// Document colors
$rhino: #284260;
$chambray: #385980;
$baliHai: #7d92ac;
$malibu: #7bb8ff;
$bitterSweet: #ff7272;
$mercury: #e9e9e9;
$kashmir: #54708e;
$bigStone: #1b2e43;
$athensGray: #e5e8ec;
$mantis: #8dc058;
$buttercup: #f5a623;
$body-bg: $rhino;
$warning: #ff8888;
$brookseyBorder: rgba(56, 89, 128, 0.29);
$grayedBackground: rgba(0, 0, 0, 0.6);
$grayedBackground-light: #f6fafe;
$white: #fff;
$gray: #ccc;
$label-color: mix($chambray, white, 66);
$widget-alternate-row-highlight: #e9e9e9;

$link-color: $malibu;
$font-size-base: 1rem;
$line-height-base: 1.5;
$font-family-bold: "ProximaNovaBold";
$font-family-regular: "ProximaNovaRegular";
$font-family-thin: "ProximaNovaThin";
$font-family-base: $font-family-regular;

// buttons
$primary: #7bb8ff;
$primary-button-hover-blue: darken($malibu, 15%);
$primary-button-focus-blue: darken($malibu, 25%);
$btn-focus-color-gray: lighten($gray, 12%);
$btn-hover-color-gray: darken($white, 10%);
$btn-active-color-gray: darken($white, 25%);
$btn-outline-color-gray: lighten($gray, 10%);
$btn-hover-color-white: darken($white, 5%);
$navtab-active-color-gray: darken($athensGray, 0.03%);
$pagination-active-color-gray: darken($white, 15%);
$clickable-btn-hover-color: darken($white, 5%);
$toggle-background-color-gray: darken($white, 30%);
$toggle-shadow-color-gray: darken($white, 70%);
$password-reset-hover-red: darken($warning, 15%);
$btn-hover-border-color-red: 1px solid $bitterSweet !important;

// pure css breakpoints
$breakpoint-xs: 27rem;
$breakpoint-sm: 35.5rem;
$breakpoint-md: 48rem;
$breakpoint-lg: 64rem;
$breakpoint-xl: 80rem;

// upper bounds for custom breakpoints
$breakpoint-phone-max: 720px;
$breakpoint-tablet-max: 1024px;

$breakpoints: (
  "sm": (
    min-width: $breakpoint-sm,
  ),
  "md": (
    min-width: $breakpoint-md,
  ),
  "lg": (
    min-width: $breakpoint-lg,
  ),
  "xl": (
    min-width: $breakpoint-xl,
  ),
  "tablet-and-up": (
    min-width: $breakpoint-phone-max + 1,
  ),
  "desktop-and-up": (
    min-width: $breakpoint-tablet-max + 1,
  ),
  // up to, but no including, [breakpoint]
  "up-to-tablet":
    (
      max-width: $breakpoint-phone-max,
    ),
  "up-to-desktop": (
    max-width: $breakpoint-tablet-max,
  ),
  "up-to-sm": (
    max-width: (
      $breakpoint-sm * 16px / 1rem,
    ) - 1px,
  ),
  "up-to-md": (
    max-width: (
      $breakpoint-md * 16px / 1rem,
    ) - 1px,
  ),
  "up-to-lg": (
    max-width: (
      $breakpoint-lg * 16px / 1rem,
    ) - 1px,
  ),
  "up-to-xl": (
    max-width: (
      $breakpoint-xl * 16px / 1rem,
    ) - 1px,
  ),
);
$layout-width: 1700px;
$content-width: calc(100% - 15em);

// border, shadow
$border-radius: 4px;
$border-radius-sm: 2px;
$border: 1px solid $mercury;
$control-theme-border-color: 1px solid mix($chambray, white, 29);

$box-shadow-sm: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
  0 1px 3px 0 rgba(0, 0, 0, 0.2); // 0px 1px 3px  rgba(0,0,0,0.2);
$box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
$box-shadow-lg: 2px 2px 30px rgba(0, 0, 0, 0.37);
$box-shadow-inset: inset 5px 1px 10px 0 #b4b5b8;
$box-shadow-menu: 0 10px 20px 0 rgba(56, 89, 128, 0.34);
$box-shadow-color-gray: 0 0 3px 2px $malibu;
$clickable-border-color-blue: 1px solid $brookseyBorder;

$curve: cubic-bezier(0.65, 0, 0.45, 1);

%text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

$inputSelector: "
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus ";

%override-autofill {
  background-color: $chambray !important;
  color: $white !important;
  box-shadow: 0 0 0 1000px $chambray inset;
  -webkit-box-shadow: 0 0 0 1000px $chambray inset;
  -webkit-text-fill-color: $white;
}
