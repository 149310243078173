// place the element on the bottom left
// element must define its own theme (width, colors, etc..)
.affix-bottom {
  position: fixed !important;
  top: auto !important;
  right: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 100;
}
