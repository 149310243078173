.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $grayedBackground;
  z-index: 100;
}

.modal-main {
  position: fixed;
  background: white;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 2em 1em 2em;
  border: 1px solid black;
  border-radius: $border-radius;
  box-shadow: $box-shadow-lg;
  color: $chambray;

  max-height: 100vh;
  overflow: auto;

  label {
    text-align: left;
  }

  input,
  textarea,
  label {
    &.pad-right {
      margin-right: 0.5rem;
    }
    &.pad-right-lg {
      margin-right: 1rem;
    }
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: $chambray;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  }

  .header {
    text-align: center;
    padding: 0;

    .close {
      color: $chambray;
      text-decoration: none;
      font-family: $font-family-bold;
      font-size: $font-size-base * 1.1;
      position: absolute;
      top: 1.5em;
      right: 1em;
    }

    .modal-title {
      font-size: $font-size-base * 1.4;
      font-family: $font-family-thin;
      color: $baliHai;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 1em 0 0;

    &.flex-end {
      justify-content: flex-end;
    }

    div {
      display: inline-flex;
      justify-content: flex-end;
      width: 100%;
    }

    button {
      padding: 1em 2.5em;
      text-align: center;
      margin-bottom: 0.5em;
      font-size: 1em;
      margin: 0 0.3em;
    }

    .custom-button {
      white-space: nowrap;
    }
  }

  .error {
    text-align: center;
    padding: 1em 0;
    color: $bitterSweet;
  }

  h3,
  .modal-h3 > label {
    color: $chambray;
    font-family: $font-family-regular;
    font-size: $font-size-base;
    font-weight: bold;
    margin: 1em 0;
  }
  .modal-h3 > label {
    margin-left: 0.5rem;
  }

  h4 {
    padding: 0 2em;
    color: $baliHai;
  }

  .success {
    text-align: center;

    i {
      color: #95d14b;
      font-size: 3.5em;
    }
  }
}


.complete-findings > .modal-main{
  overflow: scroll 
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

@include breakpoint("md") {
  .modal-main:not(.modal-extra-wide):not(.modal-full-screen) {
    width: 75%;
    max-width: 50em;

    &.modal-wide {
      max-width: 60em;
    }

    &.modal-small {
      max-width: 40em;
    }
  }

  .modal-full-screen {
    width: 100vw;
    height: 100vh;

    border-radius: 0;
    margin: 0;
    border: 0 none;
    padding: 0;
  }
}
