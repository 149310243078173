.DataPrivacyPolicyModal{
    ul{
        list-style-type: disc;
        padding-inline-start: 20px;
    }

    a{
        text-decoration: none;
        color: rgb(21, 169, 189);
        font-size: 16px;

        &:hover{
            color: rgb(24, 144, 160);
            text-decoration: underline;
        }
    }
}