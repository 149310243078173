.create-folder-btn-wrapper {
    margin-bottom: 15px;
}

.create-folder {
    float: right;
}

.font-bold {
    font-weight: bold;
}

.cursor-pointer {
    cursor: pointer;
}

.folder-container {
    color: #385980;
    border: 1px solid rgba(56, 89, 128, 0.29);
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 1em;
    font-family: "ProximaNovaRegular" !important;
    min-height: 200px;

    .heading {
        font-size: 1.1rem;
        color: #385980;
        margin: 0;
        font-weight: bold;
    }

    p {
        font-size: 15px;
    }

}

.file-container {
    min-height: 240px !important;
}

.file-img-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.file-img {
    width: 100%;
}

.arrow-holder {

    display: inline-grid;
    height: 100%;
    align-items: center;

    :nth-child(1) {
        margin-bottom: 5px;
    }

    * {
        cursor: pointer;
    }

}

.remove-btn-holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.observation-input {
    width: 100%;
    margin-top: 5px;
}

.rm-btn{
    color: red;
}