.electronic-signature-control {
  width: 100%;
  margin-top: 3em;

  .sub-header-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0.8rem 0;
  }

  .sub-header {
    color: $chambray;
    font-family: $font-family-regular;
    font-size: $font-size-base * 0.8;
    font-weight: normal;
  }

  .top-border {
    border-top: 1px solid rgba(151, 151, 151, 0.25);
    padding: 0.5em 0em;
  }

  .signature-form {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1 1 auto;
    color: $rhino;

    .modal-icon {
      :hover {
        cursor: pointer;
      }
    }

    input {
      width: auto;
      margin-right: 1em;
    }
  }

  @include breakpoint('md') {
    width: 48%;
    margin-left: 1rem;
  }
}

.uploaded-signature-container {
  margin: 10px;
  padding: 10px;
  border: 1px dashed #385980;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  height: 150px;

  .image-holder {
    display: flex;
    justify-content: center;
    width: 100%;

    img {
      max-width: 450px;
      max-height: 150px;
    }
  }
}

.upload-containers {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'ProximaNovaRegular';
  color: #385980;
}

.use-button-upload-signature {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.set-select-type-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.responsive-select-type {
  flex: 1;
  max-width: 200px;
}

.select-font-type-container {
  margin-top: 10px;
}

.font-preview {
  margin: 10px;
  padding: 10px;
  border: 1px dashed #385980;
  border-radius: 10px;
}

.font-preview-signature {
  display: flex;
  font-size: xxx-large;
  align-content: center;
  justify-content: center;
}

.font-preview-signature-text-waterfall {
  font-family: WaterfallRegular;
}

.font-preview-signature-text-stalemate {
  font-family: StalemateRegular;
}

.font-preview-signature-text-hurricane {
  font-family: HurricaneRegular;
}

.save-button-font-type-container {
  padding-top: 5px;
}

.responsive-select {
  &__control {
    max-height: 30px;
  }

  &__single-value {
    font-size: 14px;
    top: 45%;
  }

  &__placeholder {
    font-size: 14px;
    top: 45%;
  }
}

@media (max-width: 768px) {
  .set-select-type-block {
    display: block;
  }

  .responsive-select-type {
    max-width: 100%;
  }
}