.login-form {
  #{$inputSelector} {
    @extend %override-autofill;
  }
  margin: 0 1rem;

  i {
    color: $white;
  }

  .form-text-error {
    font-family: $font-family-bold;
    color: $bitterSweet;
  }

  ~ .form-actions {
    .form-text-error {
      font-family: $font-family-bold;
      color: $bitterSweet;
      margin: 0;
    }
    button {
      padding: 1em 4em;
    }

    & > .form-actions-row {
      flex-direction: row-reverse;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem;

      .forgot-password {
        margin-left: 1em;
      }
    }
  }
}
