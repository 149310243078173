.select-all {
  display: flex;

  &__label {
    flex: 1;
  }
  &__input {
    flex: 0 1 auto;
  }

  &__button {
    outline: none;
    max-height: 26px;
    display: flex;
    align-items: flex-end;
    margin-right: 0.5rem;
  }

  &__icon {
    font-size: 18px;
  }
}
