.webviewer-modal .modal-main {
  padding: 0;
}

.webviewer-container {
  position: relative;
}

.webviewer-editor {
  width: 100%;
  height: 90vh;
  padding: 0
}

.webviewer-status {
  position: absolute;
  bottom: 0;
  right: 0;
}