.project-detail-inspections {
  .tab-pane-actions {
    margin-bottom: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .right-aligned:only-child {
      margin-left: auto;
    }
  }

  .begin-inspection-schedule-actions {
    justify-content: center;
  }

  .action-due-today-cta {
    display: flex;
    flex-flow: row nowrap;
    padding: 1rem;
    border-radius: 5px;
    color: $chambray;
    background-color: rgba(197, 207, 219, 0.34);

    button {
      margin-left: 3rem;
      margin-bottom: 0;
      background-color: #7bb8ff;
      color: #fff;
    }
  }

  .action-edit-date {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
    align-items: center;

    button {
      margin-left: 0.5rem;
    }
  }

  .tab-pane-actions-primary {
    display: flex;
    flex-direction: row;
  }

  .tab-pane-actions-primary,
  .tab-pane-actions-secondary {
    & > * {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0.5rem;
      }

      .form-group {
        margin-bottom: 0;
      }
    }
    .certify-button {
      max-height: 26px;
      margin-right: 0.5em;
    }
  }

  .empty-filter {
    text-align: center;
    width: 100%;
  }

  .filter-form {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    label {
      color: $chambray;
    }
  }
}

// call to action for routine inspection
.project-detail-inspections-cta-routine {
  .tab-pane-actions {
    .tab-pane-actions-secondary {
      display: none;
    }

    .tab-pane-actions-primary {
      text-align: center;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .project-detail-inspections{
    .tab-pane-actions{
      .tab-pane-actions-secondary {
        .inspection-date-mobile{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
    
  .tab-pane-actions-primary:nth-child(2) {
    width: 100%;
    flex-direction: column;
    * {
      width: 100%;
    }
  }
 
  .tab-pane-actions-primary > div:nth-child(1) > button,
  .tab-pane-actions-primary > div:nth-child(2) > button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    & > svg,
    span {
      width: auto;
    }
  }
  .tab-pane-actions-primary > div:last-child > div:last-child {
    margin: 0;
    > div {
      padding: 0;
      > select {
        text-align: center;
      }
    }
  }
}
