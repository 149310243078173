.widget-popover-items {
    color: #84909f;

    & svg { 
        margin-right: 15px;
    }

    & p {
        font-family: "ProximaNovaRegular";
        display: inline;
        font-size: 15px;
    }

    &:hover{
        cursor: pointer;
        color: #545b64;
    }    
}