.selectClient {
  display: flex;
  flex-direction: row;
  height: auto;
  gap: 0.5rem;
}

.selectClient > button {
  white-space: nowrap;
  font-size: 0.9rem;
  border-radius: 4px;
}

.hr {
  background-color: rgb(154, 154, 154);
  position: relative;
  width: 100%;
}

.edit-user-form-section {
  margin-left: -7px !important;
  margin-right: -7px !important;
}

.edit-user-clients-table {
  margin-bottom: 10px;
  color: #385980;

  &__header {
    background-color: #c5cfdb57;
    padding: 7px 14px;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
  }

  &__no-clients {
    margin: 1rem;
    font-weight: 800;
  }

  &__body {
    padding: 0rem 1rem;
  }
}

.edit-user-clients-row {
  margin: 0px -14px;

  &__header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 20px 14px;
    font-size: larger;
    border-bottom: 1px solid #b8c1cb4d;

    &__icons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.5rem;
    }
  }

  &__body {
    padding: 1rem 14px 0rem;
    border-bottom: 2px solid #b8c1cb4d;

    &__no-projects {
      font-size: small;
      font-weight: 800;
    }

    &__auto-access {
      display: flex;
      gap: 1rem;
      flex-direction: row-reverse;
      margin: 1rem 0px;
    }
  }
}

.edit-user-clients-project {
  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
    color: #284260;

    div:nth-child(1) {
      flex-basis: 30%;
    }
    div:nth-child(2) {
      display: flex;
      justify-content: center;
      flex-basis: 20%;
    }
    div:nth-child(3) {
      display: flex;
      justify-content: center;
      flex-basis: 25%;
    }
    div:nth-child(4) {
      display: flex;
      justify-content: center;
      flex-basis: 25%;
    }
  }

  &__body {
    padding: 2rem 0rem;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    border-bottom: 1px solid #b8c1cb4d;

    div:nth-child(1) {
      flex-basis: 30%;
    }
    div:nth-child(2) {
      display: flex;
      justify-content: center;
      flex-basis: 20%;
    }
    div:nth-child(3) {
      display: flex;
      justify-content: center;
      flex-basis: 25%;
    }
    div:nth-child(4) {
      display: flex;
      justify-content: center;
      flex-basis: 25%;
    }
  }

  &__archived {
    font-size: small;
    font-weight: 800;
    margin-top: 10px;
  }
}
.cta-outer-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.cta-inner-wrapper {
  width: 50%;
  display: flex;
  justify-content: space-around;
  margin-left: 48px;
}

.access-all-projects-button-container-tenant-user-tab {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  button {
    padding: 0.1rem 0.5rem;
    background-color: $primary;
    color: white;
  }
}

@media (max-width: 568px) {
  .edit-user-form-section > div {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  .cta-inner-wrapper {
    width: 80%;
  }

  .add-client-table-head tr {
    display: flex;
    justify-content: space-between;
  }
}
