.documents {
  padding-bottom: 9rem;

  & .all-files-button{
    border: 1px solid rgba(56, 98, 128, 0.29);
    border-radius: 4px;
    height: 42px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(73, 161, 223);
    background: rgb(242, 250, 253);

    &:hover{
      cursor: pointer;
      background: #D8F4FF;
    }
  }

  & .all-files-button-icon{
    padding: 0 1rem;
  }

  & .all-files-button-text{
    padding: 0 1rem;
  }
}