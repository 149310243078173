.inspection-template-detail {
  position: relative;

  .inspection-template-header {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    .project-buttons {
      display: inline-flex;
      align-items: flex-end;
    }
  }
}
