// layout

.dropdown-menu {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
}

.dropdown-menu-toggle {
  margin-bottom: 0;
}

.public-btn{
  background-color: $malibu ;
  color: white ;
  border: 0 ;
}
.public-btn:hover{
  background-color: #2f8fff;
}

.dropdown-menu-items {
  position: absolute;
  z-index: 10;
  padding: 0;
  margin: 0;

  // default is aligned bottom-right
  top: 100%;
  right: 0;
}

.dropdown-menu-item {
  white-space: nowrap;
  padding: 0.5em 1em;

  i {
    margin-right: 0.5em;
  }
}

// theme
.dropdown-menu-items {
  list-style: none;

  color: $malibu;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow-menu;
  font-size: $font-size-base * 0.9;
  font-family: $font-family-base;
}

.dropdown-menu-item {
  cursor: pointer;

  &:hover {
    background: $athensGray;
  }
}

// closed state

.dropdown-menu .dropdown-menu-items {
  display: none;
}

// open state

.dropdown-menu--open .dropdown-menu-items {
  display: block;
}
