.new-contact-modal {
  .zip {
    width: calc(100% - 0.8em);
    float: right;
  }
}

.client-detail {
  .detail-content {
    h5 {
      margin-bottom: 1em;
    }
  }

  .detail-actions {
    display: inline-flex;
    justify-content: flex-end;
    flex-flow: row wrap;

    button {
      margin: 0 1em 1em 0;
      height: 3em;
      white-space: nowrap;

      &:last-of-type {
        margin: 0 0 1em 0;
      }
    }
  }

  .client-kebob {
    display: none;
  }
}

/** POPPER */
.client-menu {
  .create-option,
  .delete-option {
    display: none;
  }
}

@mixin collapsed-view-full {
  .client-menu .create-option,
  .client-menu .delete-option {
    display: list-item;
  }

  .add-contact-button,
  .edit-client-button {
    display: none;
  }

  .client-detail .client-kebob {
    display: block;
  }
}

@mixin collapsed-view-half {
  .client-menu .delete-option {
    display: list-item;
  }

  .edit-client-button {
    display: none;
  }

  .client-detail .client-kebob {
    display: block;
  }
}
@media screen and (min-width: $breakpoint-xl) {
  @include collapsed-view-half;
}
@media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
  @include collapsed-view-full;
}
@media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
  @include collapsed-view-half;
}
@media screen and (max-width: $breakpoint-sm) {
  @include collapsed-view-half;
}
@media screen and (max-width: $breakpoint-xs) {
  @include collapsed-view-full;
}

@media screen and (max-width: $breakpoint-md) {
  .client-detail .detail-actions {
    justify-content: flex-start;
  }
}
