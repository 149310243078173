.contextmenu {
    position: absolute;
    background: white;
    border-radius: 5px;
    padding: 0.4em 0;
    white-space: nowrap;
    border: 1px solid $brookseyBorder;
    z-index: 2;
}

.contextmenu-item {
    list-style: none;
    padding: 0 1em;

    &:hover {
        background-color: $malibu;
        cursor: default;
    }
}
