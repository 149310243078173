.client-settings-section-hidden {
  display: none;
}

.client-settings-section-visible {
  display: block;
  padding: 20px 10px;
}

.client-settings-tab-save {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  label {
    font-size: 20px;
  }
}

.client-settings-list-header {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 10px;
  color: #385980;
  font-family: 'ProximaNovaRegular';
  font-weight: 800;
}

.client-settings-list-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
}

.client-settings-list-row:nth-child(odd) {
  background-color: #f9f9f9;
}

.client-settings-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.precipitation-tracking-section {
  margin-bottom: 10rem;
}

/* Default style for desktop */
.backdated-checkbox {
  flex: 0 0 25% !important;
}

/* Adjust for tablet view */
@media (max-width: 1024px) {
  .backdated-checkbox {
    flex: 0 0 50% !important; /* Takes 50% of the screen on tablets */
  }
}

/* Adjust for mobile view */
@media (max-width: 768px) {
  .backdated-checkbox {
    flex: 0 0 100% !important; /* Takes full width on mobile */
  }
}