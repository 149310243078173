.ClientDetailsFormUI{
    &__new-folder-list{
        display: flex;
        gap: 10px;
        padding-left: 25px;
        list-style: square inside;
        font-weight: 900;

        & span:hover{
            cursor: pointer;
        }
    }

    &__new-folder-input-container{
        height: 35px;
        gap: 8px;
        margin: 16px;

        & input{
            padding-left: 12px;
            border-radius: 4px;
        }
    }

    &__new-folder-add-button{
        background: #284260;
        color: white;

        &:hover{
            background: #3b628f;
        }
    }

    //this class is applied inside "lib-form" library --
    // to "checkboxGroup"
    &__new-folder-edit-icon, &__new-folder-delete-icon{
        padding-left: 5px;

        &:hover{
            cursor: pointer;
        }
    }
}

//this class is applied inside "lib-form" library --
// to Label ("FormGroupLabel")
.formGroupLabel__reorder-button{
    padding: 1px 10px;
    margin-left: 10px;
}

//
.client-docTypes-reorder-modal{
    &__draggable-element{
        padding: 0 5px 0 5px;
        border: 1px solid gray;
        border-radius: 4px;
    }

    &__draggable-name{
        padding-left: 12px;
    }
}
