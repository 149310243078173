.project-delete-button {
  padding: 0 0 0 1.5em;
}

.division-detail {
  position: relative;

  .detail-content {
    float: none;
    padding-right: 15rem;
  }

  .division-header {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    .project-buttons {
      display: inline-flex;
      align-items: flex-end;
    }
  }

  .document-group:first-child {
    margin-top: 0;
  }
}

// division resource index
.dri {
  .dri-header {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    button {
      height: 2.5rem;
    }

    .delete-resource {
      margin-bottom: 0;
      background: $white;

      &:hover {
        background-color: $btn-hover-color-white;
      }
    }

    .overflow {
      margin-left: 1rem;
    }
  }

  .dri-filters {
    margin-right: auto;
    margin-left: 0;
    flex: 1 0 auto;
  }

  .dri-actions {
    margin-left: auto;
    margin-right: 0;
    flex: 0 0 10rem;
    text-align: right;
  }

  .dri-list {
    clear: both;
    position: relative;
    overflow-x: auto;
  }

  .dri-select {
    position: relative;
    padding-bottom: 3em;
  }

  .dri-select-text {
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 1em;

    color: $chambray;
    font-size: $font-size-base * 0.9;
  }
}

.division-removal-confirmation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  input {
    width: auto;
    margin-right: 1em;
  }
}

.division-esource-index-loading,
.dri-error {
  display: flex;
  align-items: center;
  justify-content: center;

  .form-text {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}
