.rowContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.singleRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
}

@media (max-width: 468px) {
    .permissionsModalHeader{
        padding-left: 1rem;
    }
}