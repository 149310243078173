.project-form-modal {
  .modal-wide {
    padding-top: 1rem;
  }

  .project-form {
    display: flex;
    flex-wrap: wrap;
  }

  .project-form .container {
    flex: 1 1 50%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.create-project-label{
  padding-left: 7px !important;
  label{
    font-size: 20px;
  }
}

.EVP{
  label{
    text-align: right;
  }
}