.settings-main-tenant-text {
  display: flex;
  justify-content: space-between;
}

.administration-hidden {
  display: none;
}

.administration-visible {
  display: block;
}
.administration-abc {
  > select {
    height: 60%;
  }
}

.features-table-body {
  display: flex;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  color: #385980;
  border-bottom: 1px solid rgb(224, 224, 224);
  top: 0;
  gap: 1rem;
  height: 60px;
  z-index: 2;

  & h3 {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    margin-top: 1rem;
    display: flex;
    align-items: flex-end;
    gap: 0.4rem;
  }
}

.features-table-body-toggle {
  justify-content: flex-end;
}

.project-type-table-body {
  display: flex;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  color: #385980;
  padding-left: 2rem;
  padding-right: 2rem;
  border-bottom: 1px solid rgb(224, 224, 224);
  top: 0;
  gap: 1rem;
  height: 60px;

  & h3 {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    margin-top: 1rem;
    display: flex;
    align-items: flex-end;
    gap: 0.4rem;
  }
}

.project-type-create-new-button {
  float: left;  margin-top: 15px;
}