.inspection-date-form {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &,
  .form-schema-fields {
    margin: 0;
  }

  button,
  .form-group {
    margin-bottom: 0;
  }

  button+button {
    margin-left: 0.5rem;
  }

  .icon-only-button.form-action-primary {
    margin-right: 0;
  }

  .form-control {
    padding: 0.25rem 0.5rem;
    margin-bottom: 0;
    line-height: 1;
    width: 6.5rem;
  }

  .form-actions-small {
    display: flex;
    flex-flow: row-reverse nowrap;

    .form-text {
      margin-left: 0.5rem;
    }

    .form-actions-row {
      display: flex;
    }
  }

  .form-group {
    .form-text-error {
      display: none;
    }
  }
}