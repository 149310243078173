.layout--narrow {
  width: 95%;
  padding: 1em;
  background: linear-gradient(132.05deg, #0e3353 0%, #385980 100%);
  box-shadow: 4px 4px 60px 0 rgba(0, 0, 0, 0.4);

  .app-logo {
    margin: 0 0 20px 0;
  }

  .error,
  .success {
    padding: 0 0 1em 0;
  }

  .login-logo-container{
    width: 170px;
    img{
      width: 100%;
    }
  }

  @include breakpoint("md") {
    width: 800px;
    padding: 1em 3.2em 1em 21.5em;
    min-height: 490px;
    max-height: auto;
    background: url("../../images/login-bg.png") left center no-repeat;
    margin: 0 auto;
    background-size: contain;
  }
}

.powerdby-logo-container{
  width: 350px;
  margin: 0 auto;
  color: #FFF;
  font-size: 13px;
  display: block;
  margin-top: 5px;
  .powerd-by-logo-span{
    display: flex;
    justify-content: right;
    img{
      width: 11%;
      margin-top: -5px;
      margin-left: 5px;
      @include breakpoint("md") {
        width: 5%;
      }
    }
  }

  @include breakpoint("md") {
    width: 800px;
  }

}