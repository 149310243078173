.resource-index {
  .resource-index-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    button {
      margin-bottom: 0;
    }

    .overflow {
      margin-left: 1rem;
    }
  }

  .resource-index-list {

    .dri-select-text {
      color: $chambray;
      font-size: $font-size-base * 0.9;
      margin-bottom: 0.5em;
    }

    margin-top: 2em;
    clear: both;
    position: relative;
    overflow-x: auto;
  }
}

.resource-index-loading,
.resource-index-error {
  display: flex;
  align-items: center;
  justify-content: center;

  .form-text {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}
