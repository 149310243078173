.company-logo-containers {
    margin-top: 50px;
    margin-bottom: 50px;
    font-family: "ProximaNovaRegular";
    color: #385980;
}

.file-input-container {
    display: flex;
    gap: 40px;
    margin-left: 20%;
}

.drop-area {
    width: 45%;
    height: 150px;
    border: 2px dashed #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.manual-input-holder {
    display: flex;
    gap: 40px;
    align-items: center;
}

.drop-area p {
    margin: 0;
}

.drag-over {
    background-color: #f0f0f0;
}

.or-text {
    margin: 10px 0;
    font-weight: bold;
    height: 40px;
    display: flex;
    align-items: center;
    color: #385980;
}

.file-input-label {
    height: 40px;
    padding: 10px 20px;
    background-color: #7bb8ff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-family: "ProximaNovaRegular";
    font-weight: bold;

    &:hover {
        background-color: #2f8fff;
    }
}

.file-input {
    display: none;
}

.file-selection-confirm {
    display: flex;
    gap: 30px;
}

.logo-toggle-holder {
    display: flex;
    align-items: center;
    gap: 40px;

    &h3 {
        margin: 0 !important;
    }

    margin-bottom: 20px;
}

.uploaded-img-container {
    // display: flex;
    // gap: 20px;

    .image-holder {
        width: 300px;

        img {
            width: 100%;
        }
    }

    .actionBtn-holder {
        margin-left: 3%;
        .danger {
            background: #ff0000b8;
            color: white;
            margin-left: 20px;

            &:hover {
                background: #ff0000;
            }
        }
    }
}

.replce-cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;

    button {
        background: #ff0000b8;
        color: white;
        margin-left: 20px;

        &:hover {
            background: #ff0000;
        }
    }
}