.file-row {
  &:hover {
    .file-row__column--interactable {
      opacity: 1;
    }
  }

  &__column {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: $chambray;
    padding: 0.5rem 1rem;
    width: auto;

    border-top: 1px solid rgba(56, 98, 128, 0.29);
    border-bottom: 1px solid rgba(56, 98, 128, 0.29);

    &--fill {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      color: $chambray;
    }

    &--action {
      font-size: 16px;
      color: $chambray;
      text-align: right;
      overflow: visible;
    }

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      border-left: 1px solid rgba(56, 98, 128, 0.29);
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-right: 1px solid rgba(56, 98, 128, 0.29);
    }

    &--hide-on-mobile {
      @media only screen and (max-width: 550px) {
        display: none;
      }
    }

    &--auto-width {
      white-space: nowrap;
      width: 1rem;
    }

    &--type {
      width: 7rem;
    }

    &--interactable {
      cursor: grab;
      font-size: 1.25rem;
      color: $kashmir;

      opacity: 0;

      &:hover {
        color: $malibu;
      }
    }

    &--p-2 {
      padding: 1.25rem;
    }

    &--pr-0 {
      padding-right: 0;
    }
  }
}

.folder-row{
  background: #F2FAFD;

  &:hover{
    cursor: pointer;
    background: #D8F4FF;
  }
}
