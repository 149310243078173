.inspection-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  .inspection-header-info {
    flex: 1 1 auto;

    // date and type
    h2 {
      @extend %text-overflow;
      font-size: 1.5rem;
      font-family: ProximaNovaRegular;
      line-height: 1.75rem;
      margin-bottom: 0.5em;
    }

    // inspection number and id
    h3 {
      @extend %text-overflow;
      font-size: 1rem;
      font-family: ProximaNovaThin;
      line-height: 1.75rem;
      margin-bottom: 0.1em;
    }

    // client and project name
    h5 {
      font-size: 1rem;
      font-family: ProximaNovaThin;
      line-height: 1.75rem;
      margin-bottom: 0.5rem;
    }
  }

  .inspection-header-action {
    flex: 0 0 auto;
  }
}

.inspection-header-action {
  & + & {
    margin-left: 0.5em;
  }
}

.header-icon {
  vertical-align: baseline;
  font-size: 0.75rem;
  width: auto;
  min-width: 1em;
  margin-right: 0.5em;
}

.small-button-add-to-report button {
  padding: 4px 8px; 
  margin-bottom: 5px;
  font-size: 12px;
  height: 30px;
  width: auto;
  line-height: 1.2;
}