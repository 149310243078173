.featureable-editor-opts {
  border: 1px solid lightgray;
  background-color: #fbfbfb;
  border-radius: 2px;
  padding: 1em;
  margin-bottom: 1em;

  p {
    padding: 0 !important;
    color: #385980 !important;
    font-size: 0.9rem;
  }
}

/** @todo standardized form field styles throughout app, wrt color and vertical spacing */
.shape-editor-field {
  margin-bottom: 1em;

  label {
    display: block;
    margin: 0 0 0.5em 0;
    color: #7c91ab;
    font-family: "ProximaNovaBold";
  }

  input {
    margin: 0 !important;
  }
}

.shape-editor-selection-actions {
  button {
    margin-bottom: 0;
  }
}
