//  flex-based grid with gutters

.form-schema-fields {
  $gutter-width: 1rem;

  display: flex;
  flex-flow: row wrap;

  // row has negative margin (assumes container with padding)
  margin-left: $gutter-width * -1;
  margin-right: $gutter-width * -1;

  .form-group {
    flex: 1 1 100%;

    // column has padding
    padding-left: $gutter-width / 2;
    padding-right: $gutter-width / 2;
  }
}

@include breakpoint("up-to-tablet") {
  .form-modal .modal-main {
    max-height: 85vh;
    // attempt to fix scrolling issue in mobile safari
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
  }
}

@media (max-width: 468px){
  .modal-main{
    padding-left: 1rem;
    padding-right: 1.2rem;
  }
}
