.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.detail-content {
  float: left;
}

.content-list {
  clear: both;
  margin-top: 2em;
}

.flex-row {
  display: flex;
  justify-content: space-between;

  .flex-item {
    padding: 1em;
    width: 100%;
  }
}

.align-self-center {
  align-self: center;
}

/**
 * adds gutters between grid units & aligns edges
 */
$grid-gutter: 22px / 2;

.pure-g-with-gutters {
  margin-right: $grid-gutter * -1;
  margin-left: $grid-gutter * -1;

  [class*="pure-u"] {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}

.flex-row {
  display: flex;
  flex-flow: row nowrap;
}
