.gradient {
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #284260 !important;

    display: grid;
    grid-template-columns: [page-start] 1fr [content-start] 650px [content-end] 1fr [page-end];
    grid-template-rows: [page-start] 1fr [content-start] 650px [content-end] 1fr [page-end];

    @media only screen and (max-width: 625px) {
        grid-template-columns: [content-start] 1fr [content-end];
        grid-template-rows: [content-start] 1fr [content-end];
    }

    &__content {
        grid-column: content;
        grid-row: content;
        align-self: center;
        box-shadow: 0 4px 4rem rgba(0, 0, 0, 0.25);
        width: 100%;
        height: 100%;
        background: linear-gradient(-45deg, #385980 0%, #0E3353 100%);
    }
}
