// controls - layout
// mobile: stacked, aligned left
// desktop: inline, aligned right
.inspection-findings .controls {
  display: flex;
  flex-flow: column wrap;

  .action-buttons {
    margin-left: 0.5em;
  }

  @include breakpoint('up-to-sm') {
    .action-buttons {
      order: -1;
    }
  }

  @include breakpoint('sm') {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.show-radio {
  .form-control--radio {
    padding: 0 !important;
    justify-content: space-between;
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    border: none !important;
  }
  //select second child
  .form-control--radio label {
    min-width: 176px;
  }
}

// @todo clean up styles that are not finding-specific

.inspection-findings {
  .right-aligned {
    padding: 0 0.5em 0 0.5em;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
  .checkbox {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    color: #ffffff;
    background-color: #ffffff;
    border: 1px solid #979797;
    border-radius: 2px;
  }

  .checkbox:hover {
    background-color: rgba(151, 151, 151, 0.25);
  }

  .checked {
    background-color: #7bb8ff;
    border: none;
  }

  .checked:hover {
    background-color: #7bb8ff;
  }

  h5 {
    .pointer {
      color: $chambray !important;
      text-decoration: none;
    }
  }

  .controls {
    padding: 0 0.5em 0 0.5em;

    .selection-controls {
      flex-grow: 1;
    }
    .select-all {
      position: relative;
      display: flex;
      align-items: center;

      @include breakpoint('up-to-sm') {
        margin-top: 1em;
      }

      .checkbox {
        height: 1em;
        width: 1em;
      }

      .checkmark {
        font-size: 10px;
      }

      .check-label {
        width: 100%;
        margin-left: 2em;
        line-height: 1.3em;
        color: #7d92ac;
        font-weight: bold;
        font-size: 14px;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    }
  }

  .findings-listing {
    margin-top: 1em;

    .finding-wrapper {
      min-width: 300px;
      margin: 0.5rem 0;
      position: relative;
    }

    .finding {
      margin: 0.5em;
      height: 100%;
      display: flex;
      border: 1px solid $brookseyBorder;
      border-radius: $border-radius;
      overflow: hidden;

      .finding-text-info {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        flex: 1 1 auto;
        padding: 0;

        .start-end-dates {
          margin-top: auto;
          margin-bottom: 0;
        }
      }

      .finding-content {
        padding: 1em;
        width: 100%;
        display: flex;
        align-content: space-between;
        color: $chambray;

        &:hover {
          cursor: pointer;
        }
      }

      .finding-card-checkmark {
        margin-left: auto;
        margin-right: 0;
      }

      .finding-check-holder {
        margin: 0 1em 0 0;
        flex: 0 0 1.5em;
        position: relative;
        height: 1.5em;
        width: 1.5em;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkbox {
          height: 25px;
          width: 25px;
        }
      }

      .finding-image-holder {
        flex: 0 0 7.875em;
        height: 100%;
        padding-left: 0.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .finding-thumbnail {
          max-height: 100%;
          max-width: 100%;
        }
      }

      h4 {
        font-weight: normal;
        display: flex;
        align-items: center;

        .tag {
          max-height: 2em;
        }
      }

      p {
        font-size: $font-size-base * 0.9;
      }

      .carry-over {
        font-size: $font-size-base * 0.9;
        display: inline-block;
      }

      .start-end-dates {
        font-size: $font-size-base * 0.8;
        margin-top: auto;

        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;

        strong {
          padding-left: 0.5em;
        }
      }

      .finding-content-comments {
        min-height: 2.5em;
      }
    }

    // overlay when finding is completed
    .finding-complete {
      .finding-content {
        @include background-opacity($kashmir, 0.9);
        color: $mercury;
      }

      .finding-card-checkmark {
        color: $mantis;
        width: 1.5rem;
        height: 1.5rem;
      }

      // position: absolute;
      // top: 0;
      // left: 0;
      // width: calc(100% - 1em);
      // z-index: 1;
      // display: flex;
      // justify-content: center;
      // align-items: center;

      // &:hover {
      //   cursor: pointer;
      // }

      // svg {
      //   width: 56px;
      //   height: 56px;
      //   stroke-width: 2;
      //   stroke: $mantis;
      // }
    }
  }
}

.add-finding-form,
.finding-detail-form {
  width: 100%;

  .tag {
    font-size: $font-size-base * 0.7;
    margin-right: 2em;
  }

  .finding-detail-header {
    display: flex;
    flex-direction: row;
    flex-wrap: inherit;
    align-items: center;
    border-top: 1px solid $mercury;
    border-bottom: 1px solid $mercury;
    padding: 1rem 0;

    > span {
      white-space: nowrap;
    }
  }

  .detail-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .radio-group {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;

      label {
        margin: 0.5em 1em 0.5em 0;
      }
    }
  }

  .finding-attachments-section {
    h3 {
      border-bottom: 1px solid $mercury;
      padding: 1rem 0;
    }

    .attachment-wrapper .attachment {
      border: 1px solid $brookseyBorder;
      border-radius: $border-radius;
    }

    .file-upload-button {
      justify-content: flex-end;
    }
  }
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100%;
}

.finding-detail-form,
.add-finding-form {
  .flex-row {
    justify-content: space-between;
    align-content: baseline;
    flex-flow: row wrap;

    input[type='radio'] {
      width: 1em;
      margin-right: 0.5em;
    }

    label {
      margin-right: 3em;
      padding-left: 0.1em;
      display: inline;
    }

    .carry-over {
      font-size: $font-size-base * 0.9;
    }
  }

  .finding-detail-body {
    padding: 1em 0;

    div {
      textarea {
        width: 100%;
        height: 10em;
        resize: none;
      }
    }

    .text-inputs {
      display: flex;
      flex-flow: column nowrap;

      textarea,
      input {
        width: 95%;
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 3px #ddd;
        border-radius: $border-radius;
      }
    }

    .date-inputs {
      & > label,
      & > .react-datepicker-wrapper {
        width: 95%;
        white-space: normal;
      }
    }
  }

  .add-image {
    display: inline-flex;
  }
}

// findings modal on inspection map
.findings-form {
  .react-datepicker__input-container,
  .react-datepicker-wrapper {
    width: 100%;
  }

  .add-image svg {
    margin-bottom: -0.3rem;
  }

  @include breakpoint('up-to-tablet') {
    .finding-mobile {
      span {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @include breakpoint('sm') {
    .form-control--radio {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }
}

/** editable text */
.observations-list {
  .observations-list-item {
    display: inline-flex;
    align-items: baseline;
    width: 100%;
    white-space: pre-wrap;
  }

  button {
    margin-left: 0.5rem;
    flex: 0 0 auto;

    &:first-of-type {
      margin-left: auto;
    }
  }

  button,
  textarea.form-control {
    margin-bottom: 0.25em;
  }
}

// action buttons
.finding-detail {
  margin-bottom: 1rem;
  position: relative;

  .action-buttons {
    position: absolute;
    top: 0;
    right: 0;
  }

  .pad-right {
    padding-right: 0.5rem;
  }
}

// All Findings and arrow
.finding-link {
  font-size: $font-size-base * 1.25;
  display: block;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.next-previous-button-height {
  height: 6rem;
}

.finding-error {
  color: #ff8888;
  padding: 0.75em;
}
// media query

@media (max-width: $breakpoint-md) {
  .findings-form {
    flex-direction: column !important;
  }
}

.image-number-text{
  font-size: 0.9rem;
  text-align: center;
}
