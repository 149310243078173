.data-placeholder {
  display: inline-block;
  background: linear-gradient(90deg, $gray, $white, $gray, $white);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  height: 1rem;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
