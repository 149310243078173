.calendar-report {
  font-family: 'ProximaNovaRegular';
  margin-bottom: 10px;

  & header {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    & h2 {
      font-size: 27px;
    }
  }

  &__header-tools {
    display: flex;
    justify-content: right;
    gap: 5px;
  }

  &__download-btn {
    background: #00a0df !important;
    color: #fff !important;
    width: 100px !important;

    :hover {
      background: #00a0df !important;
      color: #fff !important;
    }
  }

  //body
  &__body {
    border: 0.1px solid rgb(221, 221, 221);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    padding-bottom: 0.5rem;
  }

  //content
  &__content {
    @include hide-scrollbar;
    border-radius: 5px 5px 0 0;
    height: 400px;

    & .inspection-due__list-view {
      min-width: 600px;
    }
  }

  //footer
  &__footer {
    border-top: 1px solid rgb(196, 195, 195);
    border-radius: 0 0 5px 5px;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0rem;

    & ul {
      display: flex;
      grid-gap: 1rem;
      padding-left: 0.5rem;
      font-size: 14px;
      color: #6d747e;
      margin-top: 0rem;
      margin-bottom: 0rem;

      li {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      section:nth-child(1) {
        li:nth-child(1) {
          display: flex;
          flex-direction: row;
          column-gap: 1rem;
          flex-wrap: wrap;
        }
        li:nth-child(2) path {
          color: rgb(42, 212, 218);
        }
        p {
          display: inline;
        }
      }
    }
  }
}
