.documents-log-table{
  //body
  &__body{
    border: 0.1px solid rgb(221, 221, 221);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  //content
  &__content{
    @include hide-scrollbar;
    border-radius: 5px 5px 0 0;
    height: 460px;

    & .documents-log-table__list-view{
        min-width: 600px;
    }
  }

  &__table-head{
    background: rgb(255,255,255);
    color: #385980;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgb(196, 195, 195);
    position: sticky;
    top: 0;
    height: 60px;
    gap: 1rem;

    & h3 {
      font-size: 15px;
      font-weight: 600;
      flex-basis: 10%;
      text-align: left;
      margin-top: 1rem;
      display: flex;
      align-items: flex-end;
    }

    & h3:first-child(){
      margin-left: 1rem;
    }

    & h3:nth-child(2), h3:nth-child(3){
      flex-basis: 30%;
    }

    & h3:last-child(){
      margin-right: 1rem;
      justify-content: center;
    }
  }

  &__table-body{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;

    &:nth-child(2n+1){
      background: $widget-alternate-row-highlight;
    }

    & li {
      font-family: "ProximaNovaRegular";
      font-size: 15px;
      color: #6D747E;
      text-align: left;
      flex-basis: 10%;
      margin: 0.25rem 0 0.25rem 0;            
      height: 32px;
      line-height: 1.1;
      display: flex;
      align-items: center;

      //handling long words
      overflow: hidden;
      text-overflow: hidden;
      text-wrap: wrap;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    & li:first-child(){
      margin-left: 1rem;
    }

    & li:nth-child(3), li:nth-child(5){ // due to "ReactTooltip", the 2nd and 4th child becomes 3rd and 5th child
      flex-basis: 30%;
    }

    & li:last-child(){
      margin-right: 1rem;

      & button{
        width: 100%;
      }
    }

    & a{
      text-decoration: none;
    }
  }
}

// media Query
@media (max-width: 1300px){
  .documents-log-table{
    &__table-head{
      & h3:nth-child(2), h3:nth-child(3){
        flex-basis: 10%;
      }
    }

    &__table-body{
      & li:nth-child(3), li:nth-child(5){
        flex-basis: 10%;
      }
    }
  }
}

@media (max-width: 768px){
  .documents-log-table{
    &__table-head{
      & h3:nth-child(3), h3:nth-child(4){
        display: none;
      }

      & h3:nth-child(2){
        flex-basis: 40%;
      }

      & h3:nth-child(1), h3:nth-child(5){
        flex-basis: 15%;
      }
    }

    &__table-body{
      & li:nth-child(5), li:nth-child(7){
        display: none;
      }

      & li:nth-child(3){
        flex-basis: 40%;
      }

      & li:nth-child(1), li:nth-child(8){ 
        flex-basis: 15%;
      }
    }
  }
}