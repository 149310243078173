.frequency-settings {
  margin-top: 3em;
  // margin-left: 2em;
  width: 100%;
  .radio-list {
    border-top: 1px solid rgba(151, 151, 151, 0.25);
  }
  // Custom CheckBox CSS
  .radio-holder {
    position: relative;
    display: flex;
    align-items: center;
    margin: 1em 0 0 0;
    cursor: pointer;
    .radio {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      color: #ffffff;
      background-color: #ffffff;
      border: 1px solid #979797;
      border-radius: 16px;
      height: 16px;
      width: 16px;
      &:hover {
        background-color: rgba(151, 151, 151, 0.25);
      }
    }
    .indicator {
      position: relative;
      top: 4px;
      left: 4px;
      background-color: #fff;
      height: 8px;
      width: 8px;
      border-radius: 8px;
    }
    .checked {
      background-color: #7bb8ff;
      border: none;
      &:hover {
        background-color: #7bb8ff;
      }
    }
    .radio-label {
      width: 100%;
      margin-left: 2em;
      line-height: 1.25em;
      color: #7d92ac;
      font-weight: bold;
      font-size: 14px;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  @include breakpoint("md") {
    margin-left: 2em;
    width: 48%;
  }
}
