.documents-log{
  & header{
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 1rem;

    & h2{
      font-size: 27px;
    }
  }

  &__header-name{
      flex-basis: 40%;
      max-height: 40.5px;
  }

  &__header-tools{
      flex-basis: 60%;
      display: flex;
      justify-content: right;
      gap: 5px;
      max-height: 40.5px;
  }

  & input{
    padding: 0 1rem;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    color: rgb(80,101,126);
  }

  &__select{
    min-width: 25ch;

    & .project-log__control{
      min-height: 30px;
      border-radius: 4px !important;
      max-width: 30ch;
    }
  
    & .project-log__input{
      color: #385980;
    } 
  }

  //pagination
  &__pagination-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;

    & span{
      color: rgb(56,89,128);
      font-weight: 900;
    }
  
    & .project-log__control{
      min-height: 30px;
      max-width: 30ch;
    }
  
    & .project-log__input{
      color: #385980;
    } 
  }

  &__pagination-select{
    color: red;
    height: 32px;
    border: 1px solid rgb(226,226,226);
    color: rgb(52,125,190);
    width: 5ch;
  }
}

// media Query
@media (max-width: 768px){
  .documents-log{
    & header{
      flex-direction: column;
      max-height: 200px;
    }

    &__header-tools{
      flex-basis: 100%;
    }
  }
}