.findings-report {
  & header {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 1rem;

    & h2 {
      font-size: 27px;
    }
  }

  &__header-name {
    flex-basis: 40%;
    max-height: 40.5px;
  }

  &__header-tools {
    flex-basis: 70%;
    display: flex;
    justify-content: right;
    gap: 5px;
  }

  & input {
    padding: 0 1rem;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    color: rgb(80, 101, 126);
  }

  &__show-results-btn,
  &__download-btn {
    background: #00a0df !important;
    color: #fff !important;
    width: 140px !important;

    :hover {
      background: #00a0df !important;
      color: #fff !important;
    }

    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }

  &__pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;

    & span {
      color: rgb(56, 89, 128);
      font-weight: 900;
    }

    & .project-log__control {
      min-height: 30px;
      max-width: 30ch;
    }

    & .project-log__input {
      color: #385980;
    }
  }

  &__pagination-select {
    color: red;
    height: 32px;
    border: 1px solid rgb(226, 226, 226);
    color: rgb(52, 125, 190);
    width: 5ch;
  }
}

.findings-report-table {

  //body
  &__body {
    border: 0.1px solid rgb(221, 221, 221);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  //content
  &__content {
    @include hide-scrollbar;
    border-radius: 5px 5px 0 0;
    height: 460px;

    & .findings-report-table__list-view {
      min-width: 600px;
    }
  }

  &__table-head {
    background: rgb(255, 255, 255);
    color: #385980;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgb(196, 195, 195);
    position: sticky;
    top: 0;
    height: 60px;
    gap: 1rem;

    & h3 {
      font-size: 15px;
      font-weight: 600;
      flex-basis: 10%;
      text-align: center;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & h3:nth-child(1) {
      flex-basis: 5%;
    }

    & h3:nth-child(2),
    h3:nth-child(3),
    h3:nth-child(4),
    h3:nth-child(5) {
      flex-basis: 15%;
    }

    & h3:nth-child(6),
    h3:nth-child(7),
    h3:nth-child(8),
    h3:nth-child(9),
    h3:nth-child(10) {
      flex-basis: 7%;
    }

  }

  &__table-body {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;

    &:nth-child(2n + 1) {
      background: $widget-alternate-row-highlight;
    }

    & li {
      font-family: 'ProximaNovaRegular';
      font-size: 15px;
      color: #6d747e;
      text-align: left;
      flex-basis: 10%;
      margin: 0.25rem 0 0.25rem 0;
      height: 32px;
      line-height: 1.1;
      display: flex;
      align-items: center;

      //handling long words
      overflow: hidden;
      text-overflow: hidden;
      text-wrap: wrap;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    & li:nth-child(1) {
      flex-basis: 5%;
    }

    & li:nth-child(2),
    li:nth-child(3),
    li:nth-child(4),
    li:nth-child(5) {
      flex-basis: 15%;
    }

    & li:nth-child(6),
    li:nth-child(7),
    li:nth-child(8),
    li:nth-child(9),
    li:nth-child(10) {
      flex-basis: 7%;
    }

    & a {
      text-decoration: none;
    }
  }
}

@media (max-width: 768px) {
  .findings-report {
    & header {
      flex-direction: column;
      max-height: 200px;
    }

    &__header-tools {
      flex-basis: 100%;
    }
  }

  .findings-report-table {
    &__table-head {

      & h3:nth-child(1),
      h3:nth-child(2),
      h3:nth-child(3),
      h3:nth-child(4),
      h3:nth-child(5),
      h3:nth-child(6),
      h3:nth-child(7),
      h3:nth-child(8),
      h3:nth-child(9),
      h3:nth-child(10) {
        display: block;
        flex-basis: 10%;
      }

      h3:nth-child(4),
      h3:nth-child(5) {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      height: auto;
      gap: 0.5rem;
    }

    &__table-body {

      & li:nth-child(1),
      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4),
      li:nth-child(5),
      li:nth-child(6),
      li:nth-child(7),
      li:nth-child(8),
      li:nth-child(9),
      li:nth-child(10) {
        flex-basis: 10%;
      }

      gap: 0.5rem;
    }
  }
}