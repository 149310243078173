.calendar-view {
  position: relative;
  height: 100%;

  .calendar-header {
    display: flex;
    justify-content: space-between;
    height: 2.57rem;
    line-height: 2.57rem;
    background-color: rgba(197, 207, 219, 0.34);
    color: #284260;
    font-size: 14px;
    text-align: center;
    min-width: $breakpoint-phone-max;
  }

  .calendar-body {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 2.57rem);
    min-width: $breakpoint-phone-max;
  }
  .header-day {
    text-align: center;
    width: 20%;
  }
  .calendar-day {
    width: 20%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .today {
    background-color: rgba(145, 174, 218, 0.19);
  }
  .calendar-inspection {
    display: flex;
    background-color: #284260;
    color: #ffffff;
    margin: 0.5rem 5px 0;
    overflow: hidden;
  }
  .highlight {
    display: inline-block;
    width: 4px;
    background-color: #7bb8ff;
    margin-right: 6px;
  }
  .overdue-highlight {
    background-color: #ff7272;
  }
  .overdue {
    color: #ff7272;
  }
  .inspection-text {
    width: 100%;
  }
  .border-left {
    border-left: 1px solid rgba(55, 64, 99, 0.5);
  }
  .site-name {
    font-size: 16px;
    font-weight: 400;
    margin-top: 1em;
  }
  .client-name {
    font-size: 12px;
    margin-top: 0.7em;
  }
  .due-date {
    font-size: 14px;
    margin-top: 0.7em;
  }
  .calendar-button {
    border: 1px solid #7d92ac;
    background-color: #284260;
    margin-top: 0.7em;
    color: #e2e5e7;
  }
}
