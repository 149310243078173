.inspection-detail {
  position: relative;
  margin-bottom: 1em;
  padding-bottom: 25px;

  .submitBtnHack {
    position: fixed;
    bottom: 0;
    width: calc(100% - 15em - 140px);
    max-width: 1314px;
    box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.37);
    text-align: right;
    letter-spacing: 0.2em;
    z-index: 100;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  .react-tabs__tab {
    padding: 0.5em 2em;
    margin-right: 2px;

    &:first-child {
      padding-left: 0;
    }
  }

  .react-tabs__tab-panel {
    margin-top: 1.5em;
  }

  .add-finding {
    margin: 0;
    min-width: 130px;
  }

  .edit-findings {
    margin: 0 1em;
  }

  @include breakpoint('up-to-desktop') {
    .submitBtnHack {
      width: calc(100% - 4em);
    }
  }
}

// PRINT MODAL
.print-inspection-modal {
  .print-scope {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  [type="radio"],
  [type="checkbox"] {
    width: auto;
  }

  label {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}