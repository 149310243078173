.contacts {
  border: $border;
  border-left: none;
  border-right: none;
  padding: 1em 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .contact-wrapper {
    padding-bottom: 1em;
    padding-right: 0.5em;
    padding-left: 0.5em;
  }

  .contact {
    border: $border;
    border-radius: $border-radius;
    padding: 0.5em 1em;
    position: relative;

    h3 {
      color: $chambray;
      margin: 0;
      font-family: $font-family-regular;
      font-weight: normal;
      padding-right: 60px;
      @extend %text-overflow;
    }

    h4 {
      color: $chambray;
      margin: 0;
      font-family: $font-family-regular;
      font-weight: normal;
    }

    h5 {
      @extend %text-overflow;
    }

    .email {
      font-weight: bold;
      text-decoration: none;
      font-size: $font-size-base * 0.8;
    }

    strong {
      font-size: $font-size-base * 0.9;
    }

    .contact-controls {
      position: absolute;
      top: 0.5em;
      right: 1em;
    }
  }

  .contact-inspector-text{
    font-size: small;  
    font-weight: bold;
    color: $chambray;
}

  .contact-email-phone {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    margin-top: 0.5rem;
    color: $rhino;
    @extend %text-overflow;

    .email {
      flex: 0 1 auto;
      font-weight: normal;
      margin-bottom: 0.25rem;
      @extend %text-overflow;
    }

    .dash {
      padding: 0 5px 2px;
    }

    .phone {
      flex: 0 1 auto;
      @extend %text-overflow;
    }

    .ext {
      font-weight: lighter;
    }

    .fa {
      width: 1.5rem;
    }

    a {
      color: inherit;
      text-decoration: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.contacts-modal {
  .contact-inputs {
    min-height: 210px; // prevent height jumping
  }

  #firstName,
  #phone {
    width: calc(100% - 0.8em);
  }

  .radio-label {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    input {
      width: auto;
      margin-right: 0.5rem;
    }
  }

  hr {
    margin: 2em 0;
  }
}

.modal .add-contact-form {
  display: block;
}
