.overview{
  &__table-head{
    background: rgb(255,255,255);
    color: #385980;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgb(196, 195, 195);
    position: sticky;
    top: 0;
    gap: 1rem;
    height: 60px;
    margin-bottom: 10px;
    padding: 0 1rem;
    min-width: 800px;

    & h3 {
      font-size: 15px;
      font-weight: 600;
      flex-basis: 30%;
      text-align: left;
      margin-top: 1rem;
      display: flex;
      align-items: flex-end;
      gap: 0.4rem;
    }

    & h3:last-child(){
      flex: 40%;
    }
  }

  &__table-body{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
    min-width: 800px;

    &:nth-child(2n+1){
      background: $widget-alternate-row-highlight;
    }

    & li {
      font-family: "ProximaNovaRegular";
      font-size: 15px;
      color: #6D747E;
      text-align: left;
      flex-basis: 30%;
      margin: 0.5rem 0 0 0;            
      height: 32px;
      line-height: 1.1;
    }

    & li:not(:nth-child(n+4)){
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    & li:last-child(){
      flex: 40%;
    }

    & a{
      text-decoration: none;
    }

    & span{
      padding: 0.25rem 0.5rem;
      background-color: #7BB8FF;
      color: white;
      font-family: "ProximaNovaBold";
      font-size: 0.8rem;
      border-radius: 4px;
      width: 100px;

      &:hover{
        cursor: pointer;
        background-color: #2F8FFF;
      }
    }
  }
}