

// media query

@media (max-width: $breakpoint-lg) {
    .legend-item-form-schema{
      flex-direction: column !important;
    }
  
    .form-group--custom > div > div:last-child{
      flex-direction: column !important;
    }
  
    .form-group--custom > div > div:last-child > div{
      width: 100%;
    }
  }