.template-editor-wrapper {
  display: flex;
  flex-direction: column;
  grid-template-columns: minmax(200px, 250px) 1fr;
}

.template-editor-conditions{
  display: flex;
  justify-content: space-between;

  // logo
  &__logo-wrapper{
    position: relative;
    width: 100px;

    & label{
      position: absolute;
      top: 27%;
      height: 42px;
      width: 100%;
      font-size: 0.9rem;
      border-radius: 4px;
      padding: 0.7em 1em;
      cursor: pointer;
      background-color: buttonface;
      color:  #7c91ab;
    }

    & input[type="file"]{
      display: none;
    }

    & > div{
      position: absolute;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  &__logo{
    background-color:  rgba(125, 147, 173, 0.8);
    position: absolute;
    height: 100%;
    width: 200%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__remove-logo{
    position: absolute;
    color: red;
    top: -15px;
    right: -112px;
    z-index: 3;

    &:hover{
      cursor: pointer;
      color: rgb(179, 30, 30);
    }
  }

  // Condition group
  &__condition-group{
    width: 30%;
    position: relative;
    right: 15px;
  }
  
}

.template-editor {
  display: flex;
  flex-direction: column;
  align-items: center;

  .error-messages {
    color: $warning;
    align-self: flex-start;
    padding: 0.75em;
  }

  .question-group {
    background-color: $mercury;
    padding: 0.75em;
    border: 1px solid #d8d8d8;
    color: $chambray;
    max-width: 68em;
    margin: 2em 0em;

    .question-group-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .question-group-name-form {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        width: 100%;
        justify-content: space-between;

        .form-schema-fields {
          flex-wrap: nowrap;
        }

        .question-group-name-form-inputs {
          display: flex;
        }

        .question-group-name-form-controls {
          display: flex;
          margin: 0em 2em;

          button {
            margin: 0em 0.5em;
          }
        }
      }

      .question-group-name-input {
        min-width: fit-content;

        input {
          background: none;
          border: none;
          border-bottom: 1px solid $chambray;
        }
      }

      .group-action {
        margin-right: 0.5em;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .form-questions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .question-container {
        flex: 0 1 auto;
        display: flex;
        min-width: fit-content;
        align-items: center;

        .question {
          width: 100%;
        }

        button {
          padding: 0.5em;
          margin: 0em 0.5em 0.5em 0.5em;
        }
      }
    }

    .table-questions {
      width: 100%;

      .question-container {
        display: inline;

        .question {
          max-width: 100%;
        }

        button {
          padding: 0em 1em;
          margin: 0.5em 0.5em 0.5em 0.5em;
          max-height: 2em;
        }
      }
    }

    .editor-questions {
      .question-container {
        .question {
          display: flex column;
          border: 1px solid #d8d8d8;
          background-color: $white;
          padding: 0.5em;
          margin: 0.5em;

          &:hover {
            cursor: pointer;
          }

          .question-header {
            display: flex;
            justify-content: space-between;

            .question-actions {
              min-width: fit-content;
              margin-left: 2em;
            }
          }

          .question-type-text {
            color: $baliHai;
          }
        }

        button {
          &:hover {
            background-color: $gray;
          }
        }
      }

      .add-question {
        border: 1px solid #d8d8d8;
        background-color: $white;
        padding: 0.5em;
        color: $mercury;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.5em;

        &:hover {
          background-color: $gray;
          color: $mercury;
          cursor: pointer;
        }
      }
    }
  }

  .question-group-move {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

    .editor-questions {
      .add-question {
        display: none;
      }

      .selected-question {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
      }

      .question-container {
        .move-question-button-table {
          margin-top: 0em;
          margin-bottom: 0em;
        }

        .move-question-button-form {
          margin-right: 0em;
          margin-left: 0em;
        }
      }
    }

    button {
      margin: 1em;
    }
  }

  .add-question-group {
    display: flex;
    border: 1px solid #d8d8d8;
    margin: 1em 0em;
    height: 6em;
    width: 30em;
    max-width: 100%;
    color: $mercury;
    padding: 0.75em;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $mercury;
      color: $gray;
      cursor: pointer;
    }
  }
}

.question-group-menu {
  .delete-item {
    color: $warning;
  }
}

.removal-confirmation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  input {
    width: auto;
    margin-right: 1em;
  }
}



//media Queries------------------------------------------------------------------
@media (max-width: $breakpoint-sm) {
  .template-editor-conditions{
    &__logo{
      width: 150%;      
    }

    &__remove-logo{
      right: -60px;
    }
  }
}