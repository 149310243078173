.precipitation-log{
    & header{
        display: flex;
        justify-content: space-between;
        height: 40.5px;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }

    &__header-name{
        flex-basis: 30%;
    }

    &__header-tools{
        flex-basis: 70%;
        display: flex;
        justify-content: right;
        gap: 5px;
    }

    &__show-results-btn, &__download-btn {
        background: #00A0DF !important;
        color: #FFF !important;
        width: 140px !important;

        :hover {
            background: #00A0DF !important;
            color: #FFF !important;
        }

        @media screen and (max-width: 768px) {
            font-size: 10px;
        }
    }

    .react-datepicker__wrapper{
        height: 40.5px;
    }

    & .react-datepicker__input-container{
        height: 100%;
        border-radius: 2.5px !important;
        border: 1px solid #c5cfda;
        color: #385980;
    }

    & .react-datepicker__input-container input{
        height: 100%;
        border: 1px solid #fff;
        padding-left: 0.5rem;
    }

    .react-datepicker-wrapper, .react-datepicker__input-container {
        width: 200px;
    }
}