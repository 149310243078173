.electronic-signature-control-register {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  flex: 1 1 auto;
  width: 100%;

  .modal-icon {
    :hover {
      cursor: pointer;
    }
  }

  input {
    width: auto;
    margin-right: 1em;
  }
}
