.print-modal {
  text-align: center;

  .form-actions-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;

    .print-job-id {
      flex: 1 0 100%;
      text-align: left;
    }
  }

  .print-status {
    padding-right: 1rem;
    margin-left: 0;
    margin-right: auto;
    display: inline-flex;
    align-items: center;

    svg,
    p {
      margin: 0;
      display: inline-block;
      vertical-align: middle;
    }

    p {
      padding: 0;
    }

    .form-text {
      display: inline-block;
      text-align: left;
    }

    svg {
      margin-right: 1em;
      fill: $rhino;
    }
  }

  .inspection-select-all {
    font-size: 0.9em;
    .form-control {
      align-items: center;
    }
  }

  .border-top {
    border-top: 1px solid #222222;
    padding-top: 1em;
  }

  .two-columns > span {
    column-count: 2;
  }
}
