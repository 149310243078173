/** CustomSelect */
.form-control--customTagSelect {
  .tag-select__control {
    @include controlTheme();
    height: 2.7rem;
    &--is-focused {
      @include controlThemeFocused();
    }
  }
  box-shadow: none;
}

/** ButtonGroup */
.form-control--buttonGroup {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  border: 0 none;
}

/** Radio & checkbox group*/
.form-control--radio,
.form-control--checkboxGroup {
  label {
    display: flex;
    flex-flow: row-reverse;
    justify-content: flex-end;
    align-items: center;

    input {
      width: auto;
      margin-right: 1em;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
}

/** Option List **/
.form-control--optionList {
  .option-controls {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .clickable {
    cursor: pointer;
  }

  input {
    margin: 0.5em 0em;
    width: max-content;
    flex-grow: 1;
  }
}

/** ImageUpload */
.image-upload {
  .upload-gallery {
    width: 100%;
    margin-bottom: 1em;
  }

  .wrapper {
    border: 1px solid $brookseyBorder;
    margin: 0.5em;
  }

  .img-wrapper {
    height: 14em;
    overflow: hidden;
    text-align: center;
    width: 100%;
    background-position: center;
    background-size: cover;
  }

  img {
    height: 100%;
    width: auto;
  }

  .controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1em;

    span {
      font-weight: bold;
      color: $chambray;
      font-size: $font-size-base * 0.8;
      margin-bottom: 1em;
    }
  }

  .image-upload-actions {
    button + button {
      margin-left: 0.5rem;
    }

    .rotate-button:nth-of-type(2) i {
      transform: scaleX(-1);
    }
  }

  .label {
    vertical-align: top;
  }
}

/** ReactTag */
.react-tags-wrapper {
  position: relative;
}

.input-tag {
  background: $athensGray;
  font-size: 0.8rem;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  margin: 0 0.25rem 0.5rem;
}

.input-tag a,
.input-tag .remove {
  color: $toggle-background-color-gray;
  margin-left: 5px;
  cursor: pointer;
}

/** text area with template selection */
.form-group--textareaTmpl {
  overflow: hidden;

  .form-control-options {
    position: absolute;
    top: 0;
    right: 0.25em;
  }

  .form-control {
    margin-top: 0.75em;
    width: 100%;
  }
}

.email-message-form-control {
  display: flex;
  flex-direction: column;
}

.loading-div {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.tooltip-container {
  position: absolute;
  top: 0;
  right: 0.5em;
}
