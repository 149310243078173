.success-modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
    stroke: $mantis;
    stroke-width: 3;
  }

  .alert {
    font-size: 2rem;
    color: $bitterSweet;
  }
}
