.list-view {
  width: 100%;
  border: none;
  border-collapse: collapse;
  tr,
  th,
  td {
    border: none;
  }
  tr {
    height: 55px;
    border-bottom: 1px solid rgba(184, 193, 203, 0.3);
    color: #385980;
    font-weight: 300;
  }
  .list-header {
    font-size: 14px;
    background-color: rgba(197, 207, 219, 0.34);
    color: #284260;
    border: none;
    tr {
      height: 36px;
    }
  }

  .overdue {
    color: #fb2525;
  }
  .right {
    white-space: nowrap;
    width: 15em;
    max-width: 250px;
    text-align: end;
    a {
      margin: 0 1rem 0 0;
      flex: 0 0 auto;
    }
    > * {
      display: inline;
    }
  }

  .table-button {
    height: 30px;
    line-height: 0px;
    color: $baliHai;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid $baliHai;
    background-color: $white;
  }

  .cta-button {
    min-width: 9rem;
  }

  // match width of column header
  .overdue-items {
    min-width: 6rem;
  }
  .clickable {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }

  .dash-no-inspections {
    margin: 0 0 0 2em;
  }
}

.area-manager-table {
  position: relative;
  overflow: auto;
  .caret-cell {
    font-size: 1.5em;
    max-width: 3em;
    width: 3em;
  }
  .list-view {
    th {
      background-color: #fff;
    }
  }
  .expansion-cell {
    padding: 0;
    margin: 0;
    border-left: 0.5em solid #385980;
  }
  .pointer {
    cursor: pointer;
  }
}
