@font-face {
  font-family: ProximaNovaBold;
  src: local('MyFont'), url('../fonts/Proxima Nova Alt Bold.otf') format('opentype');
}

@font-face {
  font-family: ProximaNovaRegular;
  src: local('MyFont'), url('../fonts/Proxima Nova Alt Regular.otf') format('opentype');
}

@font-face {
  font-family: ProximaNovaThin;
  src: local('MyFont'), url('../fonts/Proxima Nova Alt Thin.otf') format('opentype');
}

@font-face {
  font-family: WaterfallRegular;
  src: local('MyFont'), url('../fonts/Waterfall-Regular.ttf') format('truetype');
}

@font-face {
  font-family: StalemateRegular;
  src: local('MyFont'), url('../fonts/Stalemate-Regular.ttf') format('truetype');
}

@font-face {
  font-family: HurricaneRegular;
  src: local('MyFont'), url('../fonts/Hurricane-Regular.ttf') format('truetype');
}