.dashboard-main {
  display: flex;
  flex-direction: column;

  .table-holder {
    position: relative;
    overflow: auto;
    height: 89%;
  }
  .calendar-holder {
    position: relative;
    height: 89%;
    overflow-x: scroll;
  }
}

.dashboard-widgets {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 50px 0 1rem;

  .user {
    h2 {
      font-family: $font-family-thin;
    }
    p {
      font-family: $font-family-bold;
      color: $baliHai;
      margin: 0;
      font-size: 1.2rem;
    }
  }

  @include breakpoint("up-to-tablet") {
    // compensate for lost of secondary nav
    padding-top: 1rem;
  }
}

.empty-dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  text-align: center;
  padding: 0 1rem;

  p {
    font-family: $font-family-bold;
    color: $baliHai;
    font-size: 1.2rem;
    margin: 0;
  }
}

.table-popper {
  width: 180px;
  .unStyled-link {
    text-decoration: none;
  }
}
.inspections-paper-page {
  height: 70vh;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff; 
}

.inspections-paper {
  height: 70vh;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 5px rgba(40, 66, 96, 0.07);
  margin-top: 1.5em;
}

.inspections-paper-header {
  height: 11%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  h3 {
    margin: 0;
  }

  .date-selector {
    display: flex;
    .active-date {
      color: #284260;
      font-size: 16px;
      font-weight: 600;
      margin: auto 10px auto 10px;
      text-align: center;
    }
    .date-change-button {
      position: relative;
      margin: auto 0;
      color: #ffffff;
      background-color: #284260;
      outline: none;
      height: 25px;
      width: 25px;
    }
    .icon-holder {
      position: absolute;
      top: 2px;
      left: 2px;
      height: 25px;
      width: 25px;
    }
  }
  .view-buttons {
    display: flex;
    justify-content: space-between;
    button {
      margin: 0;
    }
  }
  .view-button {
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    outline: none;
    padding: 0.5em;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    height: 35px;
    border-radius: 4px;

    .button-text {
      margin: 0 0 -1px 10px;
    }

    .icon {
      margin: auto 0;
    }
  }
  .active {
    background-color: #7bb8ff;
    color: #ffffff;
  }

  .dash-tab-menu {
    display: flex;
    color: rgba(56, 89, 128, 0.3);
    .dash-tab {
      margin-right: 1em;
      cursor: pointer;
    }
    .active-tab {
      color: #385980;
      border-bottom: 2px solid #385980;
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    .button-text {
      display: none;
    }
  }

  &.calendar {
    @media screen and (max-width: $breakpoint-lg) {
      .button-text {
        display: none;
      }
    }
  }
}

.popper-cta {
  display: none;
}

@media screen and (max-width: $breakpoint-md) {
  .client-name {
    display: none;
  }
  .overdue-items {
    display: none;
  }
  .cta-hider {
    display: none;
  }
  .popper-cta {
    display: block;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .dashboard-widgets {
    padding: 0 1rem;
  }
}

@media (max-width: 1024px) {
  .dashboard-widgets {
    .user {
      p {
        margin-left: -1rem;
      }
    }
  }
}
