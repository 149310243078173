.compliance-metrics{
    //header
  &__header{
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 1fr 1fr;
    grid-template-areas: 
    "title title title title title title title . search search search ellipsis"
    "bars bars bars bars bars bars bars bars bars bars bars bars";
    margin-bottom: 10px;
    grid-row-gap: 6px;
  }

  &__title{
    grid-area: title;
    color: #284260;
    font-size: 22px;
    font-weight: 500;
  }

  & input{
    grid-area: search;
    padding: 0 1rem;
    border: 1px solid rgb(163, 163, 163);
    height: 32px;
    border-radius: 4px;
    color: rgb(80,101,126);
    flex-basis: 60%;
  }

  &__ellipsis-button{
    grid-area: ellipsis;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 5px;

    & span{
      border: 1px solid rgb(163, 163, 163);
      padding: 0.9rem;
      height: 32px;
      width: 37px;
      border-radius: 4px;
      display: flex;
      place-items: center;
      color: rgb(80,101,126);

      &:hover{
        cursor: pointer;
      }
    }
  }

  &__bars{
    grid-area: bars;
    display: flex;
    gap: 0.1rem;
    justify-content: space-between;

    & > div{
      flex-basis: 33%;
    }

    & select{
      padding: 0 0.5rem;
      border: 1px solid rgb(163, 163, 163);
      background: #fff;
      height: 32px;
      min-width: 100px;
      border-radius: 4px;
      color: rgb(80,101,126);
      flex-basis: 33%;
    }

    & input{
      padding: 0 1rem;
      border: 1px solid rgb(163, 163, 163);
      height: 32px;
      border-radius: 4px;
      color: rgb(80,101,126);
      flex-basis: 33%;
    }
  }

  &__tools-options{
    position: absolute;
    height: 4.5rem;
    width: 13rem;
    background: #fff;
    color: #84909f;
    z-index: 10;
    top: 49px;
    right: -2px;
    border-radius: 4px;
    border: 0.1px solid rgb(221, 221, 221);
    box-shadow: 6px -1px 8px rgb(224, 224, 224);
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem 0;
    padding: 0 3rem 0 2rem;

    & h4{
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 20px;

      & p{
        position: relative;
        top: 2px;
      }

      &:hover{
        cursor: pointer;
      }
    }

    & h4:nth-child(2){
      & p{
        position: relative;
        left: -6px;
      }
    }
  }

  //body
  &__body{
    border: 0.1px solid rgb(221, 221, 221);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  //content
  &__content{
    @include hide-scrollbar;
    border-radius: 5px 5px 0 0;
    height: 400px;
    overflow-y: 'auto';

    & section:first-child(){
      min-width: 1200px;
    }
  }

  &__table-head{
    background: rgb(255,255,255);
    color: #385980;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgb(196, 195, 195);
    position: sticky;
    top: 0;
    gap: 1rem;
    height: 60px;
    margin-bottom: 10px;

    & > h2 {
      flex-basis: 10%;
    }

    & h3 {
      font-size: 15px;
      font-weight: 600;
      flex-basis: 30%;
      text-align: left;
      margin-top: 1rem;
      display: flex;
      align-items: flex-end;
      gap: 0.4rem;
    }

    & h3:not(:nth-child(n+3)){
      &:hover{
        cursor: pointer;
      }
    }

    & h3:nth-child(n+3){
      text-align: center;
      justify-content: center;
    }

    & h3:nth-child(5){
      flex-basis: 40%;
    }
  }

  &__table-body{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;

    &:nth-child(2n+1){
      background: $widget-alternate-row-highlight;
    }

    & li {
      font-family: "ProximaNovaRegular";
      font-size: 15px;
      color: #6D747E;
      text-align: left;
      flex-basis: 30%;
      margin: 0.5rem 0 0 0;      
      height: 32px;
      line-height: 1.1;
    }

    & li:nth-child(n+3){
      text-align: center;
    }

    & li:not(:nth-child(n+3)){
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    & li:nth-child(5){
      flex: 40%;
    }

    & a{
      text-decoration: none;
    }

    & span{
      padding: 0.5rem 1rem;
      background-color: #7BB8FF;
      color: white;
      font-family: "ProximaNovaBold";
      font-size: 0.9rem;
      border-radius: 4px;
      width: 100px;

      &:hover{
        cursor: pointer;
        background-color: #2F8FFF;
      }
    }
  }

  //footer
  &__footer{
    border-top: 1px solid rgb(196, 195, 195);
    border-radius: 0 0 5px 5px;
    padding: 0.5rem 1rem 0.5rem 2rem;
    display: flex;
    justify-content: space-between;
    height: 60px;

    & span{
      padding: 0.7em 1em;
      color: #284260;
      border: 0;
      font-family: "ProximaNovaBold";
      font-size: 1rem;
      border-radius: 4px;

      &:hover{
        cursor: pointer;
      }
    }
  }

  //pagination
  &__pagination-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;

    & span{
      color: rgb(56,89,128);
      font-weight: 900;
    }
  
    & .project-log__control{
      min-height: 30px;
      max-width: 30ch;
    }
  
    & .project-log__input{
      color: #385980;
    } 
  }
}


//media query
@media (max-width: 899px){
    .compliance-metrics{
        &__footer span{
            visibility: hidden;
        }
    }
}