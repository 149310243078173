.action-buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-end;

  &.affix-bottom {
    // layout
    width: 100%;
    margin: 0;
    padding: 1em;

    // theme
    background: $white;
    box-shadow: $box-shadow-lg;

    // position dropdown menus *above* the toggle button (ie drop-up)
    .dropdown-menu .dropdown-menu-items {
      top: auto;
      bottom: 100%;
      right: 0;
    }
  }

  .primary {
    .svg-loading {
      height: 1em;
      width: 1em;
      vertical-align: middle;
      fill: $white;
    }
  }

  .dropdown-menu + .primary,
  button + button {
    margin-left: 0.5rem;
  }
}
