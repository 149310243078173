.add-client-form {
  .pure-g {
    div {
      select {
        width: 90%;
      }
    }
  }
}

.client-delete-modal {
  input {
    width: auto;
  }
  label {
    // match p attributes
    padding: 0 2em;
    color: $baliHai;
    font-size: 1rem;
  }
}
