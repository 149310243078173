.loading-message {
  display: block;

  & > span,
  & > svg {
    display: inline-block;
    vertical-align: middle;
  }

  & > svg {
    margin-right: 0.5em;
  }
}

.app-loading-status {
  padding: 0.5em;
  color: $chambray;
}

// @include breakpoint("up-to-desktop") {
//   .app-loading-status {
//     top: 3em;
//     right: 0;
//   }
// }
