.project-url__content{
    padding: 2rem 2rem 0 2rem;
}

// media Queries

@media (max-width: $breakpoint-md) {
    .project-url__content{
        padding: 1.5rem 1.5rem 0 1.5rem;
    }
}

@media (max-width: $breakpoint-sm) {
    .project-url__content{
        padding: 1rem 1rem 0 1rem;
    }
}