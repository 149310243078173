.project-activity-log {
  .Table-Cell-Text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .header {
    float: right;
    margin-bottom: 1rem;
  }

  .MuiTypography-root {
    font-family: 'ProximaNovaRegular' !important;
  }

  &__body-data {
    &:nth-child(2n + 1) {
      background: $widget-alternate-row-highlight;
    }
  }
  button {
    margin-left: 1rem;
  }
  button:hover {
    background: #2f8fff;
  }
}
