.popper {
  position: absolute;
  z-index: 10;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow-menu;
  display: inline-block;

  ul {
    list-style: none;
    padding: 0;
    font-size: $font-size-base * 0.9;
    margin: 0;
  }
  li {
    padding: 0.5em 0;
    color: $malibu;

    &:hover {
      background: $athensGray;
    }
  }
}

.popper .pointer {
  padding: 0.5em 1em;
}
