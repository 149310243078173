.annotation-editor {
}

.annotation-editor-canvas {
}

.ol-unselectable {
  display: inline-block !important;
}

.ol-attribution {
  display: none !important;
}

.upload-annotation-modal {
  .modal-main {
    max-width: 85vw !important;
    width: 85vw !important;
  }
}

.annotation-editor-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.25em;
}

.annotation-editor-toolbar {
  padding: 1em 0;

  button {
    padding: 0.25em;
    border: 1px solid transparent;

    &.selected {
      border: 1px solid $rhino;
    }

    & + button {
      margin-left: 0.5em;
    }

    svg {
      display: block;
      width: 1.5em;
    }
  }
}

.annotation-editor-actions {
  button + button {
    margin-left: 1em;
  }
}
