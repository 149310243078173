.daily-precipitation{
  //body
  &__body{
    border: 0.1px solid rgb(221, 221, 221);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  //content
  &__content{
    @include hide-scrollbar;
    border-radius: 5px 5px 0 0;
    height: 400px;

    & .daily-precipitation__list-view{
        min-width: 600px;
    }
  }

  &__table-head, &__footer-layout{
    background: rgb(255,255,255);
    color: #385980;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgb(196, 195, 195);
    position: sticky;
    top: 0;
    height: 60px;

    & h3 {
      font-size: 15px;
      font-weight: 600;
      flex-basis: 20%;
      text-align: left;
      margin-top: 1rem;
      padding-left: 1rem;
      display: flex;
      align-items: flex-end;
      gap: 0.4rem;
    }

    & h3:first-child(){
      padding-left: 1rem;
    }
  }

  &__table-body{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;

    &:nth-child(2n+1){
      background: $widget-alternate-row-highlight;
    }

    & li {
      font-family: "ProximaNovaRegular";
      font-size: 15px;
      color: #6D747E;
      text-align: left;
      flex-basis: 20%;
      margin: 0.25rem 0 0.25rem 0;            
      height: 32px;
      line-height: 1.1;
      display: flex;
      align-items: center;
    }

    & li:first-child(){
      margin-left: 1rem;
    }

    & li:not(:nth-child(n+3)){
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    & li:last-child() path{
      &:hover{
        cursor: pointer;
      }
    }

    & a{
      text-decoration: none;
    }

    & span{
      display: inline-block;
      padding: 0.5rem 0.5rem;
      color: white;
      font-family: "ProximaNovaBold";
      font-size: 0.8rem;
      border-radius: 4px;
      width: 125px;
      height: 30px;
    }
  }

  //footer
  &__footer{
    border-radius: 0 0 5px 5px;
    height: 60px;
  }

  &__footer-layout{
    h3{
      border: 1px solid rgb(90, 90, 90);
      margin-top: 0;
      align-items: center;
    }

    h3:nth-child(4){
      border-right: none;
      justify-content: right;
      padding-right: 4px;
    }

    h3:nth-child(n+5){
      border-left: none;
      justify-content: left;
      padding-left: 4px;
    }
  }
}