.inspection-intervals{
  &__header{
    display: flex;
    justify-content: space-between;

    &:hover{
      cursor: pointer
    }
  }

  &__content{
    min-width: 600px;
  }

  &__table-head, &__table-body{
    background: rgb(255,255,255);
    color: #385980;
    border-bottom: 1px solid rgb(224,224,224);
    display: flex;
    justify-content: space-around;
    position: sticky;
    top: 0;
    gap: 1rem;
    height: 60px;
    z-index: 2;

    & > h2 {
      flex-basis: 10%;
    }

    & h3 {
      font-size: 15px;
      font-weight: 600;
      flex-basis: 20%;
      text-align: left;
      margin-top: 1rem;
      display: flex;
      align-items: flex-end;
      gap: 0.4rem;
    }

    & h3:first-child(){
      margin-left: 1rem;
    }

    & h3:nth-child(n+3){
      text-align: center;
      justify-content: center;
    }
  }

  &__table-head{
    border-bottom: 1px solid rgb(196, 195, 195);
  }

  &__create-new-button{
    float: left;
    margin-top: 15px;
  }

  &__include{
    & > div{
      display: flex;
      width: 100%;
    }
  }

  &__include-toggle{
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    position: relative;
    bottom: 0px;
  }

  &__include-edit{
    flex-basis: 50%;
    display: flex;
    align-items: center;

    &:hover{
      cursor: pointer;
    }
  }
}
