.form-actions {
  margin-left: -1rem;
  margin-right: -1rem;
  text-align: right;
}

// "small" variation
.form-actions-small {
  .form-actions-row {
    button+button {
      margin-left: 0.5em;
    }
  }
}

// "busy" state
.form-actions-is-busy {
  .icon-only-button {
    background: white;
  }

  .svg-loading {
    fill: white;
    height: auto;
    width: 1em;
    vertical-align: middle;
  }
}

// buttons
.form-actions-row {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.form-action-primary,
.form-action-secondary {
  margin: 0;
}