@mixin commonPlaceholderStyling {
    color: #E5E5E5;
    line-height: 19px;
}

.certification-due-widget-section{
    font-family: 'ProximaNovaRegular' !important;
    
    .table-fix-th-1, .table-fix-td-1{
        min-width: 145px !important;
        max-width: 145px !important;
        word-break: break-all;
    }
    .table-fix-th-2, .table-fix-td-2{
        min-width: 145px !important;
        max-width: 145px !important;
        word-break: break-all;
    }
    .table-fix-th, .table-fix-td{
        min-width: 175px;
        max-width: 175px;
        word-break: break-all;
    }
    .MuiTypography-root{
        font-family: 'ProximaNovaRegular' !important;
    }

    .MuiTableContainer-root{
        @include hide-scrollbar;
        height: 400px;
    }

    .MuiPaper-root{
        border: 0.1px solid #dddddd;
    }

    .MuiTableHead-root{
        height: 60px;

        & tr th{
            border-bottom: 1px solid #c4c3c3;    
        }        
    }

    .MuiButton-root{
        & svg path{
            color: #50657e;
        }
    }

    .MuiTableRow-root{
        & td span{
            height: 32px;
            line-height: 1.1;
        }

        &:hover{
            color: #EDF4FB;
        }
    }

    &__bars{
        justify-content: space-between;
    
        & select{
            padding: 0 0.5rem;
            border: 1px solid rgb(163, 163, 163);
            background: #fff;
            height: 32px;
            min-width: 150px;
            border-radius: 4px;
            color: rgb(80,101,126);
            flex-basis: 35%;
            position: relative;
            left: 8px;
        }
    
        & input{
            padding: 0 1rem;
            border: 1px solid rgb(163, 163, 163);
            height: 32px;
            border-radius: 4px;
            color: rgb(80,101,126);
            flex-basis: 60%;
        }
    }
}

.widget-headline{
    color: #284260;
}

.widget-popover-btn {
    min-width: 10px !important;
    background-color: #FFF !important;
    border: 1px solid #a3a3a3 !important;
    border-radius: 3px !important;
    min-height: 32px !important;
    box-shadow: none !important;
    float: right;

    svg {
        color: #88929F !important;
    }
}

.widget-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
    border-radius: 5px !important;
    margin-bottom: 3%;
    margin-top: 10px;
    max-height: 460px;
    min-height: 460px;
}

.widget-head {
    span {
        color: #385980;
        user-select: none;
        cursor: pointer;
        font-size: 15px;

    }
    .css-y8ay40-MuiTableCell-root{
        padding: 17px;
    }
}

.widget-body {
    .cell-elm {
        color: #6D747E;
        font-size: 15px;

    }

    .css-1slkafa-MuiTableCell-root{
        padding: 12px;
    }

    .widget-body-data{
        &:nth-child(2n){
            background: $widget-alternate-row-highlight;
        }
    }
}

.widget-footer{
    height: 60px;
}

.checkboxDeactive {
    color: #DCDEE7;
}

.checkboxActive {
    color: #00A0DF;
}

.widget-footer {
    border-top: 1px solid #c4c3c3;
    padding: 13px;

    button {
        text-transform: capitalize;
    }

    .certify-now-btn {
        width: 105px;
        font-weight: bold;
        color: #FFF;
        background: #4ADE86;
        border-radius: 4px;
        box-shadow: none !important;
        font-size: 12px;
    }

    .left-footer-container{
        display: flex;
        align-items: center;

        span{
            margin: 0;
            padding: 5px 10px;
            font-size: 12px;
        }
    }
}

.showmore-btn-wrapper {
    width: 25%;
    text-align: right;

    button {
        color: #284260 !important;
        font-weight: bold;
        float: right;

        .external-link-icon {
            margin-left: 10px;
        }
    }
}


//media query
@media (max-width: 899px){
    .showmore-btn-wrapper{
        visibility: hidden;
    }
}