.selection-status {
  .selection-status-msg {
    color: $kashmir;
    white-space: nowrap;
  }

  .selection-undo {
    margin-left: 0.5em;
    cursor: pointer;

    color: $malibu;
  }
}
