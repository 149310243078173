// layout
.map-editor {
  position: relative;
  background: $white;

  // ensure the map editor takes up the entire vertical space
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  .map-editor-top {
    flex: 0 0 auto;
  }

  .map-editor-main {
    flex: 1 1 100px;
    overflow: auto;
  }

  // important! ensure the editor components fill the screen
  .map-editor-layers,
  .map-editor-map,
  .positionable-navigation {
    height: 100%;
  }

  .map-editor-hamburger-icon{
    display: none;
  }

  /* theme styles */
  color: #dbdbe0;

  // white outline on user interaction on transparent buttons
  .button-transparent {
    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px rgba(250, 250, 255, 0.15) inset,
        0 0 6px rgba(250, 250, 255, 0.2) inset;
    }

    &:active,
    &:focus {
      background-image: linear-gradient(
        transparent,
        rgba(250, 250, 255, 0.05) 40%,
        rgba(250, 250, 255, 0.1)
      );
    }

    // selected state
    &.pure-button-active {
      color: white;
    }
  }

  .error {
    color: $bitterSweet;
    text-align: center;
  }
}

.map-editor-sidebar {
  position: relative;
  overflow: auto;

  .positionable-navigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.map-editor-layers {
  background-color: lightgray;
}

.map-loader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  padding-top: 1rem;

  p {
    margin: 0;
  }
}

//elements
.map-editor{
  &__legend-cards-container{
    @include hide-scrollbar;
    padding: 1px;
    position: absolute;
    bottom: 1px;
    z-index: 100;
    gap: 0 16px;
    overflow-x: scroll;
    overflow-y: hidden;
    border-radius: 8px;
    box-shadow: 4px 4px 2px gray;
  }
}

// class for Map Editor Mobile view 
.map-editor-mobile-view{
  .map-editor-sidebar{
    box-shadow: 2px 4px 12px gray;
    color: red;
    position: absolute;
    height: 12.5rem;
    width: 20rem;
    top: 10.96rem;
    left: 0.7rem;
    z-index: 1;
  }

  .map-editor-sidebar-change{
    height: 30rem;
  }

  .map-editor-main{
    background: #D3D3D3;
    justify-content: center;
  }


  .map-editor-hamburger-icon{
    display: block !important;
    position: absolute;
    top: 8.4rem;
    left: 0.7rem;
    height: 2.55rem;
    width: 2.8rem;
    padding: 0.5rem;
    z-Index: 1;
    background: rgba(33,58,87,0.8);
    display: flex;
    place-Items: center;
  }

  .map-editor-hamburger-svg{
    height: 100%;
    width: 100% !important;
  }

  .map-editor-layers > div > div:last-child > div:first-child {
    top: 4rem;
  }
}


// media query

@media (max-width: $breakpoint-md){
  .map-editor-sidebar{
    box-shadow: 2px 4px 12px gray;
    color: red;
    position: absolute;
    height: 12.5rem;
    width: 20rem;
    top: 10.96rem;
    left: 0.7rem;
    z-index: 1;
  }

  .map-editor-main > div:last-child{
    z-index: 0;
  }

  .map-editor-map{
    width: 100%;
  }

  .map-editor-hamburger-icon{
    display: block !important;
    position: absolute;
    top: 8.4rem;
    left: 0.7rem;
    height: 2.55rem;
    width: 2.8rem;
    padding: 0.5rem;
    z-Index: 1;
    background: rgba(33,58,87,0.8);
    display: flex;
    place-Items: center;
  }

  .map-editor-hamburger-svg{
    height: 100%;
    width: 100% !important;
  }

  .map-editor-layers > div > div:last-child > div:first-child {
    top: 4rem;
  }
}

.map-image-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.previous-button{
  background-color: transparent;
  border: 1px solid $baliHai;
  color: $baliHai;
  border-radius: $border-radius;
  font-size: $font-size-base * 0.9;
  height: 2.5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1rem;
  border: 1px solid $baliHai;

  &:hover{
    background-color: $btn-hover-color-gray;
  }
}

.next-button{
  background-color: $malibu;
  color: white;
  border-radius: $border-radius;
  height: 2.5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1rem;
  &:hover{
    background-color: $btn-hover-color-gray;
  }
}

.navigation-buttons{
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
}
@media (min-width: $breakpoint-md) and (orientation: landscape){
  .ol-zoom{
    top: 15px !important;
  }
}