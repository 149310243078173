.complete-findings-form {
  label[for="finding_ids"] + ul {
    margin: 0;
    list-style: none;
    column-count: 1;
    column-gap: 1em;

    @include breakpoint("sm") {
      column-count: 2;
    }

    @include breakpoint("md") {
      column-count: 3;
    }
  }

  .form-group--imageUpload {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    margin-top: 1em;
    width: 100%;

    &:nth-of-type(2) {
      background: red;
    }
  }

  .image-upload .wrapper {
    margin: 0;
  }

  .image-upload {
    & > .upload-gallery {
      margin: 0;
      margin-top: 1.5em;
    }
    button ~ .upload-gallery {
      margin-top: 0;
    }
  }

  .controls {
    padding: 0.25em;
    text-align: center;
    justify-content: center;

    .button-wrapper {
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
      flex-wrap: wrap;
    }

    button,
    button + button {
      margin: 0.1;
    }
    span {
      margin-bottom: 0.25em;
      width: 100%;
    }
  }

  .upload-gallery {
    display: inline-flex;
    flex-flow: row nowrap;
    & > div {
      width: unset; // remove pure grid
      flex: 1 1 33%;
      overflow: hidden;
    }
  }

  .img-wrapper {
    height: 5em;
  }

  .form-group--date {
    flex: 1 1 33%;
    min-width: 9em;
  }
  .form-group--textareaTmpl {
    flex: 1 1 66%;
  }
}
