.client-projects-tab{
    &__bars{
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        grid-gap: 10px;
    
        input{
            flex-basis: 50%;
            padding: 0.8em 1.5em;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            box-shadow: none;
            border: none;
            background-color: #e5e8ec;
            font-size: 0.9rem;
            color: #385980;
            font-style: italic;
            box-sizing: border-box;
        }

        select{
            flex-basis: 20%;
            padding: 0 0.5rem;
            border: 1px solid #a3a3a3;
            background: #fff;
            min-width: 256px;
            max-width: 256px;
            border-radius: 4px;
            color: #50657e;
            flex-basis: 35%;
        }
    }
}

//media query
@media (max-width: 600px){
    .client-projects-tab{
        &__bars{
            flex-direction: column;

            select{
                min-height: 45px;
                max-width: none;
            }
        }
    }
}