.template-signature-editor {
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}

button.signature-editor-btn {
  align-self: center;
  margin: 0 0.5rem 0 0;
  padding: 0.3em 0.5em;

  &:last-child {
    margin-right: 0;
  }

  .fa {
    font-size: 1rem;
    vertical-align: middle;
  }
}
