// layout

.positionable-list-title {
  padding: 0;

  .pure-menu-link {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0;

    .positionable-list-group-action {
      flex: 0 0 3em;
      text-align: center;
    }

    // title content
    span {
      flex: 1 1 auto;
    }
  }

  .pure-menu-link {
    position: relative;
  }
}

.positionable-list-search {
  padding: 0.5em 0.5em;
  background-color: #EFEFEF;

  .form-control {
    margin-bottom: 0;
    padding: .25em .5em;
  }

  position: relative;

  svg {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
}

.positionable-list-item {
  padding: 0 0.5em;

  &>.pure-menu-link {
    padding-left: 0.5em;
    padding-right: 0.5em;

    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  img,
  span,
  svg {
    display: inline-block;
    vertical-align: middle;
  }

  &:first-child:not(.positionable-list-title) {
    margin-top: -1px;
  }
}

.positionable-list-group-add {
  text-align: center;
  margin-left: auto;
  margin-right: 0;
}

.positionable-list-item-menu-toggle {
  padding: 1em;
  margin-left: auto;
  margin-right: 0;
}

.positionable-list-menu {
  z-index: 100;
}


// theme

.positionable-navigation {
  background-color: #efefef;
}

.positionable-list {
  .pure-menu-link {
    white-space: normal;

    img {
      height: auto;
      max-width: 100%;
      margin-right: 1rem;

      width: 25px;

      @include breakpoint('desktop-and-up') {
        width: 50px;
      }
    }
  }
}

.positionable-list-title {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-family: "ProximaNovaBold";

  background-color: white;
  color: #7d92ac;

  .positionable-list-group-title,
  .positionable-list-group-action {
    color: inherit;
    cursor: pointer;
  }

  .positionable-list-group-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .positionable-list-group-add {
    color: #385980;
  }

  // icon size
  .positionable-list-group-expand svg {
    font-size: 1.2rem;
  }

  .positionable-list-group-add svg {
    font-size: 0.85rem;
  }
}

.positionable-list-item,
.pure-menu-disabled {
  &>.pure-menu-link {
    border-radius: 5px;
    font-size: 0.8rem;
  }
}

.positionable-list-group-add {}

// functionality/state

// disable text selection
.positionable-list {
  user-select: none;
  border-bottom: 1px solid rgb(220,224,230);
}

// toggle viz. of list cpntent via classname
.positionable-list {
  background-color: #EFEFEF;
  .positionable-list-list {
    display: none;
  }

  &--open {
    .positionable-list-list {
      display: block;
    }

    .positionable-list-title {
      background-color: transparent;

      // separator below opened group
      .pure-menu-link::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0.7rem;
        width: calc(100% - 1.4rem);
        height: 0;
        content: "";
        border-bottom: 1px solid black;
      }

      .positionable-list-group-title {
        color: #284260;
      }
    }
  }
}

// nav item hover state
.positionable-list {

  .pure-menu-link:focus,
  .pure-menu-link:hover {
    background-color: transparent;
  }
}

// toggle active state of item
.positionable-list-item.pure-menu-active {
  &>.pure-menu-link {
    background-color: #7bb8ff;
    color: white;
    font-family: "ProximaNovaBold";
  }
}