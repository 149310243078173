.edit {
  float: right;
}

.delete {
  float: right;
}

.li-name {
  max-width: 50em;
}
