.changelog-container{
    
    font-family: ProximaNovaRegular;

    .log-label{
        font-size: 1rem;
        font-weight: bold;
        color: #385980;
        opacity: .66;
    }

    .showMoreLinkWrapper{
        text-align: center;
    }

}
