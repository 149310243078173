.public-url-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $chambray;
    color: #fff;
    padding: 0.5rem 2rem;
    width: 100%;
    height: 3.5rem;

    &__logo-img{
        height: 1.75rem;
        font-size: 1.5rem;
        width: auto;
        color: $white;

        &:hover {
            color: $btn-hover-color-gray;
        }
    }

    &__generate-qr-button{
        border: 1px solid white;
        color: white;
        background-color: transparent;

        &:hover {
            background-color: rgba(221, 220, 220, 0.3);
        }
    }

    // QR Code MODAL styles
    &__qr-image{
        position: relative;
        top: 1.4rem;
    }

    &__download-button{
        position: relative;
        border: 1px solid rgba(128, 128, 128, 0.5);
        width: 10rem;
        text-align: center;
        margin: 1rem;
    }

    &__close-button{
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: rgba(202, 202, 202, 0.2);
        border: 1px solid rgba(128, 128, 128, 0.5);
    }

    &__copy-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: $breakpoint-sm) {
        &__copy-container{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
        }

        &__download-button{
            height: 30px;
            margin: 5px;
            padding: 0;
            width: 9rem;
        }
    }
}