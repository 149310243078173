.custom-dashboard{
    position: relative;

    &__menu{
        display: flex;
        position: absolute;
        top: -100px;
        right: -23px;
        z-index: 10;
    }

    &__cog-wrapper{
        margin-top: 35px;
        height: 40px;
        width: 50px;
        display: flex;
        border-radius: 5px 0 0 5px;
        align-items: center;
        background: #1B2E43;
        color: #fff;    
    }

    &__drawer-wrapper{
        border-radius: 4px 0px 0px 4px;
        padding: 19px 20px;
        background: #1B2E43;
        color: #fff;
        height: 502px;
        width: 260px;
    }

    &__drawer{
        & p{
            font-size: 18px;
            margin: 0;
            font-weight: 700;
        }

        & span{
            padding: 0;
            height: 32px;
            width: 16px;

            & svg{
                color: #fff;
            }            
        }

        & h6{
            font-size: 18px;
            font-weight: 400;
        }

        & button{
            background: rgb(73,161,223);
            height: 34px;
            width: 100px;
            text-align: center;
            padding: 4px;

            &:hover{
                background: #2F8FFF;;
            }
        }
    }

    &__widget-list{
        padding: 15px 0;
    }

    &__widget-list-item{
        display: flex;
        gap: 10px;
        padding: 0.2rem 0;
    }    
    
    &__drag-save-button-top, &__drag-save-button-bottom{
        background: rgb(73,161,223);
        color: #fff;
        height: 34px;
        width: 100px;
        text-align: center;
        padding: 4px;
        float: right;
        position: relative;

        &:hover{
            background: #2F8FFF;
        }
    }

    &__drag-save-button-top{
        top: 20px;
    }

    &__drag-save-button-bottom{
        bottom: 20px;
    }
}




//media Queries -------------------------------------------------------------------

@media (max-width: 1024px){
    .custom-dashboard{
        padding-left: 1rem;
        padding-right: 1rem;

        &__menu{
            right: -16px;
        }
    }
}

//2 column
@media (min-width: 1300px) {
    .custom-dashboard__grid-ul{
        display: grid;
        gap: 20px;
        grid-template-columns: minmax(329px, max-content) minmax(329px, max-content);
    }
}

