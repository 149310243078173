.featureable-editor {
  .pure-g-options {
    .pure-u {
      padding: 0 0.5em;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}
