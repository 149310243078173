.registration-form {
  .flex-row {
    align-items: flex-end;
    flex-wrap: wrap;
  }

  // name inputs
  .flex-row .flex-input {
    flex-basis: 48%;
  }

  // phone inputs
  .phone.flex-row .flex-input {
    &:first-of-type {
      flex-basis: 78%;
    }
    &:last-of-type {
      flex-basis: 18%;
    }
  }

  & p {
    margin: 0;

    &:last-of-type {
      margin-bottom: 1em;
    }
  }

  .buttons {
    justify-content: flex-end;
  }

  @media (max-width: $breakpoint-sm) {
    .flex-row .flex-input,
    .phone.flex-row .flex-input:first-of-type,
    .phone.flex-row .flex-input:last-of-type {
      flex-basis: 100%;
    }
  }
}
