.post-storm {
  .project-header {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }
  .bulk-storm-form {
    margin-bottom: 5em;
  }

  .post-storm-project {
    display: flex;
    width: 100%;
    border-top: 1px solid $mercury;
    padding: 1em;
    .form-wrapper {
      width: 90%;
    }
    .project-info {
      width: 15em;
      max-width: 15em;
      overflow: hidden;
      margin: 1em 1em 0  0;
      h4{
        color: $chambray;
      }
    }
  }
}
