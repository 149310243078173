.open-findings{
    //header
    &__header{
        position: relative;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(12, 1fr);
        grid-auto-rows: 1fr 1fr;
        grid-template-areas: 
        "title title title title title title title . . . direction reload"
        "bars bars bars bars bars bars bars bars bars bars bars bars";
        margin-bottom: 10px;
        grid-row-gap: 6px;

        & span{
            border: 1px solid rgb(163, 163, 163);
            padding: 0.9rem;
            height: 32px;
            width: 37px;
            border-radius: 4px;
            display: flex;
            place-items: center;
            color: rgb(80,101,126);


            &:hover{
                cursor: pointer;
            }
        }
    }

    &__title{
        grid-area: title;
        color: #284260;
        font-size: 22px;
        font-weight: 500;
    }

    &__direction{
        grid-area: direction;
        margin-right: 2px;

        & button {
            padding: 0.4rem 0.5rem;
        }
    }

    &__reload-button{
        grid-area: reload;
        font-size: 17px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover{
            color: #284260;
            cursor: pointer;
        }
    }

    &__ellipsis-button{
        grid-area: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__bars{
        grid-area: bars;
        display: flex;
        gap: 1rem;
        justify-content: space-between;

        & select{
            padding: 0 0.5rem;
            border: 1px solid rgb(163, 163, 163);
            background: #fff;
            height: 32px;
            min-width: 150px;
            border-radius: 4px;
            color: rgb(80,101,126);
            flex-basis: 35%;
        }

        & input{
            padding: 0 1rem;
            border: 1px solid rgb(163, 163, 163);
            height: 32px;
            border-radius: 4px;
            color: rgb(80,101,126);
            flex-basis: 60%;
        }
    }


    &__ellipsis-options{
        position: absolute;
        height: 4.5rem;
        width: 13rem;
        background: #fff;
        color: #84909f;
        z-index: 10;
        top: 49px;
        right: -2px;
        border-radius: 4px;
        border: 0.1px solid rgb(221, 221, 221);
        box-shadow: 6px -1px 8px rgb(224, 224, 224);
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem 0;
        padding: 0 3rem 0 2rem;

        & h4{
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 20px;

            & p{
                position: relative;
                top: 2px;
            }

            &:hover{
                cursor: pointer;
            }
        }

        & h4:nth-child(2){

            & p{
                position: relative;
                left: -6px;
            }
        }
    }

    //body
    &__body{
        border: 0.1px solid rgb(221, 221, 221);
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }

    //content
    &__content{
        @include hide-scrollbar;
        border-radius: 5px 5px 0 0;
        padding: 0 0 1rem 0;
        height: 400px;
        position: relative;
    }

    &__table-wrapper{
        display: flex;
    }

    &__table-left{
        width: 50%;

        & .open-findings__table-head{
            justify-content: space-around;
            color: #385980;
            gap: 2rem;
        }

        & .open-findings__table-body{   
            justify-content: space-around;
            gap: 0.5rem;
        }

        & h3 {
            flex-basis: 50%;
            gap: 0.4rem;
        }

        & h3:first-child(){
            padding-left: 1rem;
        }

        & h3:not(:nth-child(n+3)){
            &:hover{
                cursor: pointer;
            }
        }

        & input {
            flex-basis: 1%;
            margin-left:10px;
            margin-bottom:0.5em;
            height:1em;
            width: 1em;

         }

        & li {
            flex-basis: 50%;
        }
    }

    &__table-right{
        width: 50%;

        h3{
            justify-content: center;
            flex-basis: 33%;
        }

        h3:nth-child(n+2){
            flex-basis: 40%;
        }

        li{
            text-align: center;
            flex-basis: 33%;
        }
    }

    &__table-right-inner-wrapper{
        min-width: 500px;
    }

    &__table-head{
        background: rgb(255,255,255);
        color: #385980;
        display: flex;
        border-bottom: 1px solid rgb(196, 195, 195);
        position: sticky;
        top: 0;
        height: 60px;
        margin-bottom: 10px;

        & h3 {
            font-size: 15px;
            font-weight: 600;
            display: flex;
            align-items: flex-end;
        }
    }

    &__table-body{
        display: flex;
        align-items: center;

        &:nth-child(2n+1){
            background: $widget-alternate-row-highlight;
        }

        & li {
            font-family: "ProximaNovaRegular";
            font-size: 15px;
            list-style: none;
            color: #6D747E;
            margin: 0.5rem 0 0 0;
            height: 32px;
            line-height: 1.1;
        }

        & li:not(:nth-child(n+3)){
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        & a{
            text-decoration: none;
        }

        & span{
            padding: 0.5rem 1rem;
            background-color: #7BB8FF;
            color: white;
            font-family: "ProximaNovaBold";
            font-size: 0.9rem;
            border-radius: 4px;
            width: 100px;

            &:hover{
                cursor: pointer;
                background-color: #2F8FFF;
            }
        }
    }

    //footer
    &__footer{
        border-top: 1px solid rgb(196, 195, 195);
        border-radius: 0 0 5px 5px;
        padding: 0.5rem 0rem 0.5rem 4rem;
        display: flex;
        justify-content: space-between;
        height: 60px;
        position: relative;

        & span:nth-child(2){
            padding: 0.7em 1em;
            color: #284260;
            border: 0;
            font-family: "ProximaNovaBold";
            font-size: 1rem;
            border-radius: 4px;
            position: absolute;
            left: 51%;
            transform: translateX(-51%);

            &:hover{
                cursor: pointer;
            }
        }

        & span:nth-child(3){
            padding: 0.7em 1em;
            color: #284260;
            border: 0;
            font-family: "ProximaNovaBold";
            font-size: 1rem;
            border-radius: 4px;

           
        }

      
    }
}



//media query
@media (max-width: 899px){
    .open-findings{
        &__footer span:nth-child(2){
            visibility: hidden;
        }
    }
}