.forgot-password {
  #{$inputSelector} {
    @extend %override-autofill;
  }

  h3,
  p {
    font-family: $font-family-regular;
    color: $white;
  }

  p {
    margin-bottom: 2rem;
  }

  .transparent {
    color: $link-color;
  }

  .form-actions-row {
    margin-top: 1rem;
  }

  .form-text-error {
    font-family: $font-family-bold;
    color: $bitterSweet;
    margin: 0;
  }

  button {
    padding: 1em 4em;
  }

  .success ~ .form-actions .form-text-error {
    color: $mantis;
  }

  .success-message {
    color: $mantis;
    margin-right: 1rem;
  }

  a {
    margin-right: 1rem;
  }
}
