%small {
  font-size: $font-size-base * 0.8;
  width: auto;
  padding: 0.2em 1em;

  i {
    font-size: $font-size-base * 0.6;
  }
}

// forms, inputs, buttons
label {
  font-size: $font-size-base * 0.9;
  color: $chambray;

  &.secondary {
    color: $white;
  }

  & ~ p.description,
  & ~ span.description {
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
    font-style: italic;
  }
}

button {
  border: 0;
  font-family: $font-family-bold;
  font-size: $font-size-base * 0.9;
  border-radius: $border-radius;

  &.primary {
    background-color: $malibu;
    color: $white;

    &:hover {
      background-color: $primary-button-hover-blue;
    }

    &:focus {
      background-color: $primary-button-focus-blue;
    }

    &.add {
      padding: 0;
      border-radius: $border-radius;
      font-size: $font-size-base * 1.5;
      display: inline-block;
      width: 1.8em;
      height: 1.8em;
    }
  }

  &.reversed {
    color: $malibu;
    background-color: $white;
    svg g {
      stroke: $malibu;
    }
  }

  &.transparent {
    background-color: transparent;
    color: $white;
  }

  &.icon-only-button {
    width: 3em;
    height: 3em;
    padding: 0;
    text-align: center;

    &.tiny {
      width: 1.7em;
      height: 1.7em;
    }
  }

  &.grey {
    color: $rhino;
    background-color: $athensGray;

    a {
      color: $rhino;
    }
  }
  &:focus {
    background-color: $btn-hover-color-gray;
  }

  &.warn {
    color: $bitterSweet !important;
    border: 1px solid $bitterSweet !important;

    &:hover {
      background-color: $btn-hover-color-gray;
    }

    &:disabled {
      background-color: $mercury !important;
      color: $white !important;
      border: none !important;
    }
  }

  &.outline {
    border: 1px solid $baliHai;
    color: $baliHai;
    background-color: $white;

    &:hover {
      background-color: $btn-hover-color-gray;
    }

    &:active {
      background-color: $btn-active-color-gray;
    }

    &:focus {
      background-color: $btn-focus-color-gray;
    }
  }

  &:hover {
    background-color: $btn-hover-color-gray;
  }

  //   $btn-hover-color-gray

  &:active {
    background-color: $btn-active-color-gray;
  }

  &:focus {
    background-color: $btn-focus-color-gray;
  }

  &.small {
    @extend %small;
  }

  &.create-new {
    float: right;
  }

  &.back {
    display: inline-block;
    float: left;
  }

  &:disabled {
    background-color: $kashmir !important;
    cursor: not-allowed;
  }
}

.small {
  select {
    @extend %small;

    &:hover {
      background-color: darken($white, 5%);
    }

    &:active {
      background-color: darken($white, 9%);
    }

    &:focus {
      background-color: darken($white, 14%);
    }
  }
}

input {
  line-height: $line-height-base * 1.1;
  width: 100%;
  outline: 0;

  &.search-control {
    padding: 0.8em 1.5em;
    border-radius: $border-radius;
    -webkit-border-radius: $border-radius;
    box-shadow: none;
    border: none;
    background-color: $athensGray;
    width: 50%;
    font-size: $font-size-base * 0.9;
    color: $chambray;
    font-style: italic;
    box-sizing: border-box;

    &:active {
      background-color: darken($athensGray, 3%);
      border: 1px solid $brookseyBorder;
    }
    &:focus {
      border: 1px solid $brookseyBorder;
    }
  }
}

.form-group.bottom-outline {
  .form-control {
    background-color: transparentize($color: $white, $amount: 0.1);
  }

  & > label {
    color: white;
  }
}

select,
input,
textarea {
  &:disabled {
    cursor: not-allowed;
    background-color: $mercury;
  }
}

/**
 * targets iOS Sarafi
 */
@supports (-webkit-overflow-scrolling: touch) {
  // remove iOS level styles
  input {
    -webkit-appearance: none !important;
  }

  input[type="checkbox"],
  input[type="radio"] {
    width: 2em;
    height: 2em;
    -webkit-appearance: initial !important;
  }
  input[type="checkbox"] {
    -webkit-appearance: checkbox !important;
  }
  input[type="radio"] {
    -webkit-appearance: radio !important;
  }
}

// classes for toggle switch
.toggle-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .toggle {
    appearance: none;
    min-width: 56px;
    height: 26px;
    display: inline-block;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: $toggle-background-color-gray;
    transition: background-color ease 0.3s;

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      width: 22px;
      height: 22px;
      background: $white;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      font: 10px/22px Helvetica;
      text-transform: uppercase;
      font-weight: bold;
      text-indent: -22px;
      word-spacing: 37px;
      color: $white;
      text-shadow: -1px -1px hotpink;
      white-space: nowrap;
      box-shadow: 0 1px 2px $toggle-shadow-color-gray;
      transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
    }

    &:checked {
      background-color: $malibu;
    }

    &:checked:before {
      left: 32px;
    }
  }
  label {
    padding: 0.5em;
  }
}

// PASSWORDS
.form-group--password {
  .password-control {
    position: relative;
  }

  .password-visibility-toggle {
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none;
    margin: 0.5em;
  }
  // safari autofill icon
  input::-webkit-credentials-auto-fill-button,
  input::-webkit-textfield-decoration-container {
    margin-right: 2em;
  }

  // IE / Edge show password icon
  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }
}



// #B8D4FD -checked disabled
// #DADFE4 -unchecked disabled
//toggle switch
.form-group--toggleButton-lg{

  display: flex;
  
  label{
    width: 50%;
  }

  .switch-wrapper{
    width: 50%;
  }

  // for large switch

  .switch {
    position: relative;
    // display: inline-block;
    float: right;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }


}


.form-group--toggleButton-sm{


  display: flex;
  
  label{
    width: 50%;
  }

  .switch-wrapper{
    width: 50%;
  }

  // for small switch

  .switch {
    position: relative;
    // display: inline-block;
    float: right;
    width: 40px;
    height: 20px;
  }

  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider.disabled {
    background-color: #DADFE4 !important;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 13px;
    left: 6px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider.disabled{
    background-color: #B8D4FD !important;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

}

.precipitation-error-msg-wrapper{
  text-align: center;
  font-size: 13px;
  color: red;
}



// width classes
@each $percent in 0 10 20 30 40 50 60 70 80 90 100 {
  .input-width-#{$percent} {
    width: #{$percent + "%"};
  }
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100%;
}

.filter select {
  $size: 5px;

  box-sizing: border-box;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, $chambray 50%),
    linear-gradient(135deg, $chambray 50%, transparent 50%);
  background-position: calc(100% - (#{$size} * 3)) calc(1em + 2px),
    calc(100% - (#{$size} * 2)) calc(1em + 2px);
  background-size: $size $size, $size $size;
  background-repeat: no-repeat;

  font-family: $font-family-bold;
  font-size: $font-size-base * 0.9;
  color: $baliHai;
  background-color: $white;

  &:hover {
    background-color: darken($white, 5%);
  }
}

@include breakpoint("up-to-tablet") {
  .manual-search-container{
  margin-left: 1rem;  
  }
  .report-search-container{
    margin-left: 1rem;
  }
}
