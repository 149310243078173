.bread-crumbs {
    display: flex;
    position: relative;
    bottom: 0;
    height: 1em;
    width: 100%;
    justify-content: center;

    .bread-crumb {
        height: .5em;
        width: .5em;
        border-radius: .5em;
        margin: .5em;
        background-color: $mercury;
    }
    .active {
        background-color: $malibu;
    }
}