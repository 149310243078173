/**
 * adapted from https://leaverou.github.io/css3patterns/
 */
.featureable-editor-canvas {
  margin: 1em 0;

  .canvas-container {
    @include backgroundGrid();

    margin: 0 auto;
  }
}
