.upload-control {
  display: flex;
  align-items: center;
  border: 1px dashed $chambray;
  border-radius: $border-radius;
  cursor: pointer;
  text-align: center;

  color: $chambray;
  font-size: 14px;
  padding: 0.5rem;

  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &__icon {
    font-size: 18px;

    & + span {
      margin-left: 1rem;
    }
  }

  transition: all 100ms ease-in;
  background-color: transparent;

  &--active {
    background-color: $grayedBackground-light;
    border-style: solid;
  }

  // large call to action
  &--cta {
    margin: 0 auto;
    height: 100px;
    width: 100%;
    max-width: 35em;
    justify-content: center;
  }

  // thin row
  &--row {
    height: 50px;
    margin-bottom: 1rem;
  }
}
