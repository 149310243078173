// https://codepen.io/haniotis/pen/KwvYLO

.successNotification {
  width: 100vw;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;

  &.show {
    display: flex;
    background-color: $grayedBackground;

    svg {
      animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
    }

    .outline {
      animation: stroke 0.6s $curve forwards;
    }

    .checkmark {
      animation: stroke 0.3s $curve 0.8s forwards;
    }
  }

  svg {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: $white;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px $mantis;
  }

  .outline {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $mantis;
    fill: none;
  }

  .checkmark {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px $mantis;
    }
  }
}
