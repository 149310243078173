.convert-job {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .convert-job-progress {
    line-height: 1.5rem;
  }

  .convert-job-status {
    padding-left: 1rem;
    display: inline-block;
    vertical-align: middle;

    svg {
      display: block;
    }
  }

  .form-text {
    display: inline-block;
  }
}
