.sw-dropdown {
  position: relative;
  cursor: pointer;
  font-size: inherit;

  &--disabled {
    cursor: not-allowed;
  }

  &__menu {
    display: none;
    position: absolute;
    bottom: 0;
    right: -0.5rem;
    text-align: left;

    &--active {
      display: block;
      position: absolute;
      bottom: 0;
      right: -0.5rem;
    }
  }

  &__menu-inner {
    background-color: white;
    z-index: 5;
    border-radius: 0.3rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    min-width: 150px;
    font-size: 14px;
    position: absolute;
    right: 0;
    text-align: left;
  }

  &__menu-item {
    padding: 0.5rem;

    &:hover {
      text-decoration: underline;
      background-color: $athensGray;
    }

    &:first-child {
      margin-top: 0.5rem;
    }
  }
}
