.project-delete-button {
  padding: 0 0 0 1.5em;
}

.group-detail {
  position: relative;

  .detail-content {
    float: none;
    padding-right: 15rem;
  }

  .group-header {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .document-group:first-child {
    margin-top: 0;
  }
}
