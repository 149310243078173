.map-interaction-context-menu {
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}

.map-interaction-legend-card {
  z-index: 200;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 1px;
  display: flex;
  justify-content: center;
}