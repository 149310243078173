table.list-table {
  width: 100%;
  border-collapse: separate;
  border: 0;
  border-spacing: 0 0.6rem;
  font-size: $font-size-base * 0.9;
  font-family: $font-family-regular;
  color: $chambray;
  margin-top: -10px; /* correct offset on first border spacing if desired */

  thead {
    background: none;

    th {
      border-bottom: $clickable-border-color-blue;
      border-style: solid none;
      color: $chambray;
      opacity: 0.66;
    }
  }

  tr.clickable:hover {
    cursor: pointer;
    background-color: $clickable-btn-hover-color;
    text-decoration: underline;
  }

  td {
    border: $clickable-border-color-blue;
    border-style: solid none;
    padding: 1em;

    &:first-child {
      border-left-style: solid;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      border-left-width: 1px;
    }

    &:last-child {
      border-right-style: solid;
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  td.no-border {
    border: none;
  }

  .buttons {
    text-align: center;
    button + button {
      margin-left: 0.5em;
    }
  }

  .truncate {
    overflow: hidden;
  }

  .select,
  .detail {
    width: 3em;
    text-align: center;
  }

  .detail:hover {
    cursor: pointer;
  }
}

table.list-table--empty {
  .select {
    display: none;
  }
}

// media query -- only applicable for admin page

@media (max-width: 768px) {
  .only-for-admin:nth-child(3), .only-for-admin:nth-child(4) {
    display: none;
  }
}
