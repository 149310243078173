.LegendCardContent{
    line-height: 1;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 4px 4px 2px gray;
    min-width: 350px;

    //header
    header{
        display: flex;
        justify-content: space-between;

        & span:nth-child(1){
            color: #284260;
            font-weight: 900;
        }

        & span:nth-child(2){
            color: #b4b4b6;
            font-size: 14px;

            &:hover{
                cursor: pointer;
                color: #88888a;
            }
        }
    }

    // added
    &__added{
        display: flex;
        justify-content: space-between;
        color: #b4b4b6;

        p{
            margin-bottom: 0;
            color: #b4b4b6;
        }

        span{
            color: #284260;
        }

        input{
            height: 25px;
            width: 125px;
            padding-left: 5px;
        }

        button{
            margin: 0 4px;
        }
    }

    &__added-left{
        display: flex;
        align-items: center;

        & span:first-child(){
            color: #b4b4b6;
        }
    }

    &__added-right{
        display: flex;
        align-items: center;
    }

    // center
    &__center{
        display: flex;
        justify-content: space-between;

        p:nth-child(1){
            color: #b4b4b6;
        }
    
        p:nth-child(2){
            color: blue;
            
            &:hover{
                cursor: pointer;
                // color: rgb(180, 3, 3);
            }
        }
    }

    //footer
    footer{
        display: flex;
        justify-content: space-around;

        p{
            border-radius: 4px;
            padding: 4px 8px;
            margin: 0;

            &:hover{
                cursor: pointer;
                background: rgb(197, 196, 196);
            }
        }

        & p:nth-child(1){
            border: 1px solid gray;
            color: #b4b4b6;

            &:hover{
                background: #3d6694;
            }
        }

        & p:nth-child(2){
            background-color: #284260;
            border: 1px solid #284260;
            color: #fff;

            &:hover{
                background: #3d6694;
            }
        }
    }
}