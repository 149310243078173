.calendar__wrapper{
  /* VARIABLES */
  --calendar-main-color: #1a8fff;
  --calendar-text-color: #777;
  --calendar-text-color-light: #ccc;
  --calendar-border-color: #eee;
  --calendar-bg-color: #f9f9f9;
  --calendar-neutral-color: #fff;
    
  /* GENERAL */
  
  & * {
    box-sizing: border-box;
  }
  
  & body {
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--calendar-text-color);
    background: var(--calendar-bg-color);
    position: relative;
  }
  
  & header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid var(--calendar-border-color);
    background: var(--calendar-neutral-color);
  }
  
  & header #logo {
    font-size: 175%;
    text-align: center;
    color: var(--calendar-main-color);
    line-height: 1;
  }
  
  & header #logo .icon {
    padding-right: 0.25em;
  }
  
  & main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
  }
}


.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  white-space: nowrap;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
  }

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--calendar-neutral-color);
  border: 1px solid var(--calendar-border-color);
}

.calendar__header{
  display: flex;
  justify-content: center;
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1rem 0;
  height: 60px;
  border-bottom: 1px solid #c4c3c3;
  color: #385980;
}

.calendar .header .icon {
cursor: pointer;
transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transition: 0.25s ease-out;
  color: var(--calendar-main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--calendar-text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--calendar-border-color);
}

.calendar .body .cell {
  @include hide-scrollbar;
  position: relative;
  height: 18.93rem;
  border-right: 1px solid var(--calendar-border-color);
  cursor: pointer;
  background: var(--calendar-neutral-color);
  transition: 0.25s ease-out;
  font-size: 1.5em;
  padding: 0;
}


.calendar .body .cell:hover {
  background: var(--calendar-bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .today {
  background: #eef8ff;
}

.calendar .body .row {
  border-bottom: 1px solid var(--calendar-border-color);
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  background: #fff;
  color: #6D747E;
  font-size: 22px;
  position: sticky;
  top: -0.75px;
  margin-bottom: 0.5rem;
}

.calendar .body .disabled {
  color: var(--calendar-text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--calendar-main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.calendar-inspection-detail {
  border: 1px solid #c4c3c3;
  border-radius: 4px;
  box-shadow: 2px 4px 8px #c4c3c3;
  font-family: "ProximaNovaRegular";
  color: #6D747E;
  font-size: 16px;
  padding: 0.3rem 0.1rem;
  margin: 0 0.1rem 0.5rem 0.1rem;

  h6{
    margin: 0.5rem 0;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
  }

  &__cta{
    overflow: hidden;
    
    a span{
      border-radius: 4px;
      font-size: 11px;
      padding: 3px;
      color: white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 75px;
    }
  }

  & a{
      text-decoration: none;
  }

  button{
    height: 30px;
    width: 100%;
    font-size: 10px !important;
    padding: 0;
  }
}

.renderHeader__center{
  position: relative;
  top: 3px;
  font-size: 16px;
  flex-basis: 5%;
}

.renderHeader__left{
  display: flex;
  align-items: center;
  gap: 0 1rem;
  font-size: 18px;
}

.renderHeader__right{
  display: flex;
  align-items: center;
  gap: 0 1rem;
  font-size: 18px;
}

.calendar__body{
  min-width: 640px;
}

// Classes to dynamically handle CTA Color
.calendar-view-show-project-color{
  background: #7BB8FF;

  &:hover{
    cursor: pointer;
    background-color: #2F8FFF;
  }
}

.calendar-view-start-inspection-color{
  background: #4ADE86;

  &:hover{
      cursor: pointer;
      background-color: #3CB26C;
  }
}

.calendar-view-finish-color{
  background: #FFD158;

    &:hover{
        cursor: pointer;
        background-color: #ddb13e;
    }
}

.calendar-view-past-due-color{
  background: #B33A3A;

  &:hover{
      cursor: pointer;
      background-color: #da4545;
  }
}