.reset-password-form {
  #{$inputSelector} {
    @extend %override-autofill;
  }

  label {
    padding-top: 0;
  }

  h3 {
    color: $white;
    margin-bottom: 1.5em;
  }

  button {
    padding: 1em 4em;
    &.transparent {
      color: $link-color;
    }
  }

  svg {
    stroke-width: 2;
    stroke: $mantis;
    width: 3em;
    height: 3em;
  }

  .success-modal {
    text-align: center;
  }

  .buttons {
    text-align: right;
  }

  .form-text-error {
    font-family: $font-family-bold;
    color: $bitterSweet;
  }

  ~ .form-actions {
    .form-text-error {
      font-family: $font-family-bold;
      color: $bitterSweet;
      margin: 0;
    }
  }
}
