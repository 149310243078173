.user-details {
  .card-content {
    overflow: hidden;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .fa {
      width: 1.5rem;
    }
  }
}

.modal .user-details {
  p {
    padding: 0;
  }
}
