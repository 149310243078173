.loading-modal-container,
#loading {
  position: relative;
}

.loading-modal {
  // layout

  width: 100%;
  height: 100%;
  z-index: 1000;

  position: absolute;
  top: 0;
  left: 0;

  background: $grayedBackground;

  // content

  display: flex;
  justify-content: center;
  align-items: center;

  .animated-line {
    stroke: $rhino;
    fill: $rhino;
  }

  svg {
    width: 5em;
    height: 5em;
    border-radius: 50%;
    stroke-width: 1;
    fill: $rhino;
    margin: 1rem;
  }
}
