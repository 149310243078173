.confirmation-modal {
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    font-family: $font-family-regular;
    p {
      margin: 0.5rem 0;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      margin-left: 0.5rem;
    }
  }
}
