.compact-picker {
  span {
    top: 0 !important;
  }

  input {
    box-shadow: none !important;
    width: 100% !important;
  }
}
