.public-site-map-card{
    border: 1px solid rgba(199, 198, 198, 0.5);
    box-shadow: 2px 4px 12px rgba(197, 197, 197, 0.4);

    &__background-image{
        background-size: contain;
        height: 280px;
        border-bottom: 0.5px solid rgba(199, 198, 198, 0.5);
    }
}

// media queries
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .public-site-map-card{   
        &__background-image{
            background-size: contain;
            height: 200px;
        }
    }
}